import { Component, OnInit, OnDestroy, ViewEncapsulation, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { I18nService } from '@app/i18n';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SupabaseService } from '@app/services/supabase.service';
import { hexToRgb } from '@app/utils/hex-to-rgb';
import { WidgetConfigurations } from '@app/models/widget-configurations.model';
import * as Sentry from "@sentry/angular";


@UntilDestroy()
@Component({
  selector: 'booking-review-page',
  templateUrl: './cancellation-confirmed.component.html',
  styleUrls: ['./cancellation-confirmed.component.css', '../../../styles.css'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class CancellationConfirmedComponent implements OnInit, OnDestroy {

  @Input() bookingUuid?: string;

  tenantId?: string;
  restaurantId?: string;

  isLoading = true;

  // Widget Configuration
  classes: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private supabaseService: SupabaseService,
  ) {};

  async ngOnInit() {
    const params = this.activatedRoute.snapshot.queryParams;
    this.tenantId = params.tenantId;
    this.restaurantId = params.restaurantId;

    await this.i18nService.init(environment.defaultLanguage);

    this.getWidgetConfigurations();
    Sentry.setUser({
      'Tenant Id': this.tenantId,
      'Restaurant Id': this.restaurantId,
    })

    this.isLoading = false;
    const event = new CustomEvent('hideFrameworkLoader');
    window.dispatchEvent(event);

  }


  ngOnDestroy() {
  };



  private getWidgetConfigurations() {
    if (this.tenantId) {


      this.supabaseService.getWidgetConfigurations(this.tenantId!, this.restaurantId!).subscribe({
        next: response => {
          if(response.error) throw response.error;
          const data = response.data;
          if (!data) {
            return;
          }
          this.setStyle(data);
        },
        error: error => {
          console.error(error);
        }
      });
    }

  };

  private setStyle(widgetConfigurations: WidgetConfigurations){
    if(widgetConfigurations.primaryColor){
      document.documentElement.style.setProperty('--primary-color', widgetConfigurations.primaryColor);
      // document.documentElement.style.setProperty('--p', widgetConfigurations.primaryColor);
      const primaryColorLight = hexToRgb(widgetConfigurations.primaryColor);
      // create a lighter color if primar color is set with opacity
      if(primaryColorLight){
        document.documentElement.style.setProperty('--primary-color-lighter', `rgba(${primaryColorLight.r}, ${primaryColorLight.g}, ${primaryColorLight.b}, 0.5)`);
      }
    }
    if(widgetConfigurations.primaryColorContrast){
      document.documentElement.style.setProperty('--primary-color-contrast', widgetConfigurations.primaryColorContrast);
    }
    if(widgetConfigurations.hasFrame){
      this.classes += ' shadow-xl';
    }

    if(widgetConfigurations.font){
      document.documentElement.style.fontFamily = widgetConfigurations.font;
      document.body.style.fontFamily = widgetConfigurations.font;
    }

    if(widgetConfigurations.classes){
      this.classes += ' ' + widgetConfigurations.classes;
    }

    if(widgetConfigurations.fontColor){
      document.body.style.color = widgetConfigurations.fontColor;
    }

    if(widgetConfigurations.borderRadius){
      document.documentElement.style.setProperty('--border-radius', widgetConfigurations.borderRadius + 'px');
    }

    if(widgetConfigurations.containerColor){
      document.documentElement.style.setProperty('--container-color', widgetConfigurations.containerColor);
    }

    if(widgetConfigurations.backgroundColor){
      document.body.style.backgroundColor = widgetConfigurations.backgroundColor;
    }

  };


};
