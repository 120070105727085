/*! countries-list v3.1.1 by Annexare | MIT */
var u = {
  AF: "Africa",
  AN: "Antarctica",
  AS: "Asia",
  EU: "Europe",
  NA: "North America",
  OC: "Oceania",
  SA: "South America"
};
var a = {
  AD: {
    name: "Andorra",
    native: "Andorra",
    phone: [376],
    continent: "EU",
    capital: "Andorra la Vella",
    currency: ["EUR"],
    languages: ["ca"]
  },
  AE: {
    name: "United Arab Emirates",
    native: "\u062F\u0648\u0644\u0629 \u0627\u0644\u0625\u0645\u0627\u0631\u0627\u062A \u0627\u0644\u0639\u0631\u0628\u064A\u0629 \u0627\u0644\u0645\u062A\u062D\u062F\u0629",
    phone: [971],
    continent: "AS",
    capital: "Abu Dhabi",
    currency: ["AED"],
    languages: ["ar"]
  },
  AF: {
    name: "Afghanistan",
    native: "\u0627\u0641\u063A\u0627\u0646\u0633\u062A\u0627\u0646",
    phone: [93],
    continent: "AS",
    capital: "Kabul",
    currency: ["AFN"],
    languages: ["ps", "uz", "tk"]
  },
  AG: {
    name: "Antigua and Barbuda",
    native: "Antigua and Barbuda",
    phone: [1268],
    continent: "NA",
    capital: "Saint John's",
    currency: ["XCD"],
    languages: ["en"]
  },
  AI: {
    name: "Anguilla",
    native: "Anguilla",
    phone: [1264],
    continent: "NA",
    capital: "The Valley",
    currency: ["XCD"],
    languages: ["en"]
  },
  AL: {
    name: "Albania",
    native: "Shqip\xEBria",
    phone: [355],
    continent: "EU",
    capital: "Tirana",
    currency: ["ALL"],
    languages: ["sq"]
  },
  AM: {
    name: "Armenia",
    native: "\u0540\u0561\u0575\u0561\u057D\u057F\u0561\u0576",
    phone: [374],
    continent: "AS",
    capital: "Yerevan",
    currency: ["AMD"],
    languages: ["hy", "ru"]
  },
  AO: {
    name: "Angola",
    native: "Angola",
    phone: [244],
    continent: "AF",
    capital: "Luanda",
    currency: ["AOA"],
    languages: ["pt"]
  },
  AQ: {
    name: "Antarctica",
    native: "Antarctica",
    phone: [672],
    continent: "AN",
    capital: "",
    currency: [],
    languages: []
  },
  AR: {
    name: "Argentina",
    native: "Argentina",
    phone: [54],
    continent: "SA",
    capital: "Buenos Aires",
    currency: ["ARS"],
    languages: ["es", "gn"]
  },
  AS: {
    name: "American Samoa",
    native: "American Samoa",
    phone: [1684],
    continent: "OC",
    capital: "Pago Pago",
    currency: ["USD"],
    languages: ["en", "sm"]
  },
  AT: {
    name: "Austria",
    native: "\xD6sterreich",
    phone: [43],
    continent: "EU",
    capital: "Vienna",
    currency: ["EUR"],
    languages: ["de"]
  },
  AU: {
    name: "Australia",
    native: "Australia",
    phone: [61],
    continent: "OC",
    capital: "Canberra",
    currency: ["AUD"],
    languages: ["en"]
  },
  AW: {
    name: "Aruba",
    native: "Aruba",
    phone: [297],
    continent: "NA",
    capital: "Oranjestad",
    currency: ["AWG"],
    languages: ["nl", "pa"]
  },
  AX: {
    name: "Aland",
    native: "\xC5land",
    phone: [358],
    continent: "EU",
    capital: "Mariehamn",
    currency: ["EUR"],
    languages: ["sv"],
    partOf: "FI"
  },
  AZ: {
    name: "Azerbaijan",
    native: "Az\u0259rbaycan",
    phone: [994],
    continent: "AS",
    continents: ["AS", "EU"],
    capital: "Baku",
    currency: ["AZN"],
    languages: ["az"]
  },
  BA: {
    name: "Bosnia and Herzegovina",
    native: "Bosna i Hercegovina",
    phone: [387],
    continent: "EU",
    capital: "Sarajevo",
    currency: ["BAM"],
    languages: ["bs", "hr", "sr"]
  },
  BB: {
    name: "Barbados",
    native: "Barbados",
    phone: [1246],
    continent: "NA",
    capital: "Bridgetown",
    currency: ["BBD"],
    languages: ["en"]
  },
  BD: {
    name: "Bangladesh",
    native: "Bangladesh",
    phone: [880],
    continent: "AS",
    capital: "Dhaka",
    currency: ["BDT"],
    languages: ["bn"]
  },
  BE: {
    name: "Belgium",
    native: "Belgi\xEB",
    phone: [32],
    continent: "EU",
    capital: "Brussels",
    currency: ["EUR"],
    languages: ["nl", "fr", "de"]
  },
  BF: {
    name: "Burkina Faso",
    native: "Burkina Faso",
    phone: [226],
    continent: "AF",
    capital: "Ouagadougou",
    currency: ["XOF"],
    languages: ["fr", "ff"]
  },
  BG: {
    name: "Bulgaria",
    native: "\u0411\u044A\u043B\u0433\u0430\u0440\u0438\u044F",
    phone: [359],
    continent: "EU",
    capital: "Sofia",
    currency: ["BGN"],
    languages: ["bg"]
  },
  BH: {
    name: "Bahrain",
    native: "\u200F\u0627\u0644\u0628\u062D\u0631\u064A\u0646",
    phone: [973],
    continent: "AS",
    capital: "Manama",
    currency: ["BHD"],
    languages: ["ar"]
  },
  BI: {
    name: "Burundi",
    native: "Burundi",
    phone: [257],
    continent: "AF",
    capital: "Bujumbura",
    currency: ["BIF"],
    languages: ["fr", "rn"]
  },
  BJ: {
    name: "Benin",
    native: "B\xE9nin",
    phone: [229],
    continent: "AF",
    capital: "Porto-Novo",
    currency: ["XOF"],
    languages: ["fr"]
  },
  BL: {
    name: "Saint Barthelemy",
    native: "Saint-Barth\xE9lemy",
    phone: [590],
    continent: "NA",
    capital: "Gustavia",
    currency: ["EUR"],
    languages: ["fr"]
  },
  BM: {
    name: "Bermuda",
    native: "Bermuda",
    phone: [1441],
    continent: "NA",
    capital: "Hamilton",
    currency: ["BMD"],
    languages: ["en"]
  },
  BN: {
    name: "Brunei",
    native: "Negara Brunei Darussalam",
    phone: [673],
    continent: "AS",
    capital: "Bandar Seri Begawan",
    currency: ["BND"],
    languages: ["ms"]
  },
  BO: {
    name: "Bolivia",
    native: "Bolivia",
    phone: [591],
    continent: "SA",
    capital: "Sucre",
    currency: ["BOB", "BOV"],
    languages: ["es", "ay", "qu"]
  },
  BQ: {
    name: "Bonaire",
    native: "Bonaire",
    phone: [5997],
    continent: "NA",
    capital: "Kralendijk",
    currency: ["USD"],
    languages: ["nl"]
  },
  BR: {
    name: "Brazil",
    native: "Brasil",
    phone: [55],
    continent: "SA",
    capital: "Bras\xEDlia",
    currency: ["BRL"],
    languages: ["pt"]
  },
  BS: {
    name: "Bahamas",
    native: "Bahamas",
    phone: [1242],
    continent: "NA",
    capital: "Nassau",
    currency: ["BSD"],
    languages: ["en"]
  },
  BT: {
    name: "Bhutan",
    native: "\u02BCbrug-yul",
    phone: [975],
    continent: "AS",
    capital: "Thimphu",
    currency: ["BTN", "INR"],
    languages: ["dz"]
  },
  BV: {
    name: "Bouvet Island",
    native: "Bouvet\xF8ya",
    phone: [47],
    continent: "AN",
    capital: "",
    currency: ["NOK"],
    languages: ["no", "nb", "nn"]
  },
  BW: {
    name: "Botswana",
    native: "Botswana",
    phone: [267],
    continent: "AF",
    capital: "Gaborone",
    currency: ["BWP"],
    languages: ["en", "tn"]
  },
  BY: {
    name: "Belarus",
    native: "\u0411\u0435\u043B\u0430\u0440\u0443\u0301\u0441\u044C",
    phone: [375],
    continent: "EU",
    capital: "Minsk",
    currency: ["BYN"],
    languages: ["be", "ru"]
  },
  BZ: {
    name: "Belize",
    native: "Belize",
    phone: [501],
    continent: "NA",
    capital: "Belmopan",
    currency: ["BZD"],
    languages: ["en", "es"]
  },
  CA: {
    name: "Canada",
    native: "Canada",
    phone: [1],
    continent: "NA",
    capital: "Ottawa",
    currency: ["CAD"],
    languages: ["en", "fr"]
  },
  CC: {
    name: "Cocos (Keeling) Islands",
    native: "Cocos (Keeling) Islands",
    phone: [61],
    continent: "AS",
    capital: "West Island",
    currency: ["AUD"],
    languages: ["en"]
  },
  CD: {
    name: "Democratic Republic of the Congo",
    native: "R\xE9publique d\xE9mocratique du Congo",
    phone: [243],
    continent: "AF",
    capital: "Kinshasa",
    currency: ["CDF"],
    languages: ["fr", "ln", "kg", "sw", "lu"]
  },
  CF: {
    name: "Central African Republic",
    native: "K\xF6d\xF6r\xF6s\xEAse t\xEE B\xEAafr\xEEka",
    phone: [236],
    continent: "AF",
    capital: "Bangui",
    currency: ["XAF"],
    languages: ["fr", "sg"]
  },
  CG: {
    name: "Republic of the Congo",
    native: "R\xE9publique du Congo",
    phone: [242],
    continent: "AF",
    capital: "Brazzaville",
    currency: ["XAF"],
    languages: ["fr", "ln"]
  },
  CH: {
    name: "Switzerland",
    native: "Schweiz",
    phone: [41],
    continent: "EU",
    capital: "Bern",
    currency: ["CHE", "CHF", "CHW"],
    languages: ["de", "fr", "it"]
  },
  CI: {
    name: "Ivory Coast",
    native: "C\xF4te d'Ivoire",
    phone: [225],
    continent: "AF",
    capital: "Yamoussoukro",
    currency: ["XOF"],
    languages: ["fr"]
  },
  CK: {
    name: "Cook Islands",
    native: "Cook Islands",
    phone: [682],
    continent: "OC",
    capital: "Avarua",
    currency: ["NZD"],
    languages: ["en"]
  },
  CL: {
    name: "Chile",
    native: "Chile",
    phone: [56],
    continent: "SA",
    capital: "Santiago",
    currency: ["CLF", "CLP"],
    languages: ["es"]
  },
  CM: {
    name: "Cameroon",
    native: "Cameroon",
    phone: [237],
    continent: "AF",
    capital: "Yaound\xE9",
    currency: ["XAF"],
    languages: ["en", "fr"]
  },
  CN: {
    name: "China",
    native: "\u4E2D\u56FD",
    phone: [86],
    continent: "AS",
    capital: "Beijing",
    currency: ["CNY"],
    languages: ["zh"]
  },
  CO: {
    name: "Colombia",
    native: "Colombia",
    phone: [57],
    continent: "SA",
    capital: "Bogot\xE1",
    currency: ["COP"],
    languages: ["es"]
  },
  CR: {
    name: "Costa Rica",
    native: "Costa Rica",
    phone: [506],
    continent: "NA",
    capital: "San Jos\xE9",
    currency: ["CRC"],
    languages: ["es"]
  },
  CU: {
    name: "Cuba",
    native: "Cuba",
    phone: [53],
    continent: "NA",
    capital: "Havana",
    currency: ["CUC", "CUP"],
    languages: ["es"]
  },
  CV: {
    name: "Cape Verde",
    native: "Cabo Verde",
    phone: [238],
    continent: "AF",
    capital: "Praia",
    currency: ["CVE"],
    languages: ["pt"]
  },
  CW: {
    name: "Curacao",
    native: "Cura\xE7ao",
    phone: [5999],
    continent: "NA",
    capital: "Willemstad",
    currency: ["ANG"],
    languages: ["nl", "pa", "en"]
  },
  CX: {
    name: "Christmas Island",
    native: "Christmas Island",
    phone: [61],
    continent: "AS",
    capital: "Flying Fish Cove",
    currency: ["AUD"],
    languages: ["en"]
  },
  CY: {
    name: "Cyprus",
    native: "\u039A\u03CD\u03C0\u03C1\u03BF\u03C2",
    phone: [357],
    continent: "EU",
    capital: "Nicosia",
    currency: ["EUR"],
    languages: ["el", "tr", "hy"]
  },
  CZ: {
    name: "Czech Republic",
    native: "\u010Cesk\xE1 republika",
    phone: [420],
    continent: "EU",
    capital: "Prague",
    currency: ["CZK"],
    languages: ["cs"]
  },
  DE: {
    name: "Germany",
    native: "Deutschland",
    phone: [49],
    continent: "EU",
    capital: "Berlin",
    currency: ["EUR"],
    languages: ["de"]
  },
  DJ: {
    name: "Djibouti",
    native: "Djibouti",
    phone: [253],
    continent: "AF",
    capital: "Djibouti",
    currency: ["DJF"],
    languages: ["fr", "ar"]
  },
  DK: {
    name: "Denmark",
    native: "Danmark",
    phone: [45],
    continent: "EU",
    continents: ["EU", "NA"],
    capital: "Copenhagen",
    currency: ["DKK"],
    languages: ["da"]
  },
  DM: {
    name: "Dominica",
    native: "Dominica",
    phone: [1767],
    continent: "NA",
    capital: "Roseau",
    currency: ["XCD"],
    languages: ["en"]
  },
  DO: {
    name: "Dominican Republic",
    native: "Rep\xFAblica Dominicana",
    phone: [1809, 1829, 1849],
    continent: "NA",
    capital: "Santo Domingo",
    currency: ["DOP"],
    languages: ["es"]
  },
  DZ: {
    name: "Algeria",
    native: "\u0627\u0644\u062C\u0632\u0627\u0626\u0631",
    phone: [213],
    continent: "AF",
    capital: "Algiers",
    currency: ["DZD"],
    languages: ["ar"]
  },
  EC: {
    name: "Ecuador",
    native: "Ecuador",
    phone: [593],
    continent: "SA",
    capital: "Quito",
    currency: ["USD"],
    languages: ["es"]
  },
  EE: {
    name: "Estonia",
    native: "Eesti",
    phone: [372],
    continent: "EU",
    capital: "Tallinn",
    currency: ["EUR"],
    languages: ["et"]
  },
  EG: {
    name: "Egypt",
    native: "\u0645\u0635\u0631\u200E",
    phone: [20],
    continent: "AF",
    continents: ["AF", "AS"],
    capital: "Cairo",
    currency: ["EGP"],
    languages: ["ar"]
  },
  EH: {
    name: "Western Sahara",
    native: "\u0627\u0644\u0635\u062D\u0631\u0627\u0621 \u0627\u0644\u063A\u0631\u0628\u064A\u0629",
    phone: [212],
    continent: "AF",
    capital: "El Aai\xFAn",
    currency: ["MAD", "DZD", "MRU"],
    languages: ["es"]
  },
  ER: {
    name: "Eritrea",
    native: "\u12A4\u122D\u1275\u122B",
    phone: [291],
    continent: "AF",
    capital: "Asmara",
    currency: ["ERN"],
    languages: ["ti", "ar", "en"]
  },
  ES: {
    name: "Spain",
    native: "Espa\xF1a",
    phone: [34],
    continent: "EU",
    capital: "Madrid",
    currency: ["EUR"],
    languages: ["es", "eu", "ca", "gl", "oc"]
  },
  ET: {
    name: "Ethiopia",
    native: "\u12A2\u1275\u12EE\u1335\u12EB",
    phone: [251],
    continent: "AF",
    capital: "Addis Ababa",
    currency: ["ETB"],
    languages: ["am"]
  },
  FI: {
    name: "Finland",
    native: "Suomi",
    phone: [358],
    continent: "EU",
    capital: "Helsinki",
    currency: ["EUR"],
    languages: ["fi", "sv"]
  },
  FJ: {
    name: "Fiji",
    native: "Fiji",
    phone: [679],
    continent: "OC",
    capital: "Suva",
    currency: ["FJD"],
    languages: ["en", "fj", "hi", "ur"]
  },
  FK: {
    name: "Falkland Islands",
    native: "Falkland Islands",
    phone: [500],
    continent: "SA",
    capital: "Stanley",
    currency: ["FKP"],
    languages: ["en"]
  },
  FM: {
    name: "Micronesia",
    native: "Micronesia",
    phone: [691],
    continent: "OC",
    capital: "Palikir",
    currency: ["USD"],
    languages: ["en"]
  },
  FO: {
    name: "Faroe Islands",
    native: "F\xF8royar",
    phone: [298],
    continent: "EU",
    capital: "T\xF3rshavn",
    currency: ["DKK"],
    languages: ["fo"]
  },
  FR: {
    name: "France",
    native: "France",
    phone: [33],
    continent: "EU",
    capital: "Paris",
    currency: ["EUR"],
    languages: ["fr"]
  },
  GA: {
    name: "Gabon",
    native: "Gabon",
    phone: [241],
    continent: "AF",
    capital: "Libreville",
    currency: ["XAF"],
    languages: ["fr"]
  },
  GB: {
    name: "United Kingdom",
    native: "United Kingdom",
    phone: [44],
    continent: "EU",
    capital: "London",
    currency: ["GBP"],
    languages: ["en"]
  },
  GD: {
    name: "Grenada",
    native: "Grenada",
    phone: [1473],
    continent: "NA",
    capital: "St. George's",
    currency: ["XCD"],
    languages: ["en"]
  },
  GE: {
    name: "Georgia",
    native: "\u10E1\u10D0\u10E5\u10D0\u10E0\u10D7\u10D5\u10D4\u10DA\u10DD",
    phone: [995],
    continent: "AS",
    continents: ["AS", "EU"],
    capital: "Tbilisi",
    currency: ["GEL"],
    languages: ["ka"]
  },
  GF: {
    name: "French Guiana",
    native: "Guyane fran\xE7aise",
    phone: [594],
    continent: "SA",
    capital: "Cayenne",
    currency: ["EUR"],
    languages: ["fr"]
  },
  GG: {
    name: "Guernsey",
    native: "Guernsey",
    phone: [44],
    continent: "EU",
    capital: "St. Peter Port",
    currency: ["GBP"],
    languages: ["en", "fr"]
  },
  GH: {
    name: "Ghana",
    native: "Ghana",
    phone: [233],
    continent: "AF",
    capital: "Accra",
    currency: ["GHS"],
    languages: ["en"]
  },
  GI: {
    name: "Gibraltar",
    native: "Gibraltar",
    phone: [350],
    continent: "EU",
    capital: "Gibraltar",
    currency: ["GIP"],
    languages: ["en"]
  },
  GL: {
    name: "Greenland",
    native: "Kalaallit Nunaat",
    phone: [299],
    continent: "NA",
    capital: "Nuuk",
    currency: ["DKK"],
    languages: ["kl"]
  },
  GM: {
    name: "Gambia",
    native: "Gambia",
    phone: [220],
    continent: "AF",
    capital: "Banjul",
    currency: ["GMD"],
    languages: ["en"]
  },
  GN: {
    name: "Guinea",
    native: "Guin\xE9e",
    phone: [224],
    continent: "AF",
    capital: "Conakry",
    currency: ["GNF"],
    languages: ["fr", "ff"]
  },
  GP: {
    name: "Guadeloupe",
    native: "Guadeloupe",
    phone: [590],
    continent: "NA",
    capital: "Basse-Terre",
    currency: ["EUR"],
    languages: ["fr"]
  },
  GQ: {
    name: "Equatorial Guinea",
    native: "Guinea Ecuatorial",
    phone: [240],
    continent: "AF",
    capital: "Malabo",
    currency: ["XAF"],
    languages: ["es", "fr"]
  },
  GR: {
    name: "Greece",
    native: "\u0395\u03BB\u03BB\u03AC\u03B4\u03B1",
    phone: [30],
    continent: "EU",
    capital: "Athens",
    currency: ["EUR"],
    languages: ["el"]
  },
  GS: {
    name: "South Georgia and the South Sandwich Islands",
    native: "South Georgia",
    phone: [500],
    continent: "AN",
    capital: "King Edward Point",
    currency: ["GBP"],
    languages: ["en"]
  },
  GT: {
    name: "Guatemala",
    native: "Guatemala",
    phone: [502],
    continent: "NA",
    capital: "Guatemala City",
    currency: ["GTQ"],
    languages: ["es"]
  },
  GU: {
    name: "Guam",
    native: "Guam",
    phone: [1671],
    continent: "OC",
    capital: "Hag\xE5t\xF1a",
    currency: ["USD"],
    languages: ["en", "ch", "es"]
  },
  GW: {
    name: "Guinea-Bissau",
    native: "Guin\xE9-Bissau",
    phone: [245],
    continent: "AF",
    capital: "Bissau",
    currency: ["XOF"],
    languages: ["pt"]
  },
  GY: {
    name: "Guyana",
    native: "Guyana",
    phone: [592],
    continent: "SA",
    capital: "Georgetown",
    currency: ["GYD"],
    languages: ["en"]
  },
  HK: {
    name: "Hong Kong",
    native: "\u9999\u6E2F",
    phone: [852],
    continent: "AS",
    capital: "City of Victoria",
    currency: ["HKD"],
    languages: ["zh", "en"]
  },
  HM: {
    name: "Heard Island and McDonald Islands",
    native: "Heard Island and McDonald Islands",
    phone: [61],
    continent: "AN",
    capital: "",
    currency: ["AUD"],
    languages: ["en"]
  },
  HN: {
    name: "Honduras",
    native: "Honduras",
    phone: [504],
    continent: "NA",
    capital: "Tegucigalpa",
    currency: ["HNL"],
    languages: ["es"]
  },
  HR: {
    name: "Croatia",
    native: "Hrvatska",
    phone: [385],
    continent: "EU",
    capital: "Zagreb",
    currency: ["EUR"],
    languages: ["hr"]
  },
  HT: {
    name: "Haiti",
    native: "Ha\xEFti",
    phone: [509],
    continent: "NA",
    capital: "Port-au-Prince",
    currency: ["HTG", "USD"],
    languages: ["fr", "ht"]
  },
  HU: {
    name: "Hungary",
    native: "Magyarorsz\xE1g",
    phone: [36],
    continent: "EU",
    capital: "Budapest",
    currency: ["HUF"],
    languages: ["hu"]
  },
  ID: {
    name: "Indonesia",
    native: "Indonesia",
    phone: [62],
    continent: "AS",
    capital: "Jakarta",
    currency: ["IDR"],
    languages: ["id"]
  },
  IE: {
    name: "Ireland",
    native: "\xC9ire",
    phone: [353],
    continent: "EU",
    capital: "Dublin",
    currency: ["EUR"],
    languages: ["ga", "en"]
  },
  IL: {
    name: "Israel",
    native: "\u05D9\u05B4\u05E9\u05B0\u05C2\u05E8\u05B8\u05D0\u05B5\u05DC",
    phone: [972],
    continent: "AS",
    capital: "Jerusalem",
    currency: ["ILS"],
    languages: ["he", "ar"]
  },
  IM: {
    name: "Isle of Man",
    native: "Isle of Man",
    phone: [44],
    continent: "EU",
    capital: "Douglas",
    currency: ["GBP"],
    languages: ["en", "gv"]
  },
  IN: {
    name: "India",
    native: "\u092D\u093E\u0930\u0924",
    phone: [91],
    continent: "AS",
    capital: "New Delhi",
    currency: ["INR"],
    languages: ["hi", "en"]
  },
  IO: {
    name: "British Indian Ocean Territory",
    native: "British Indian Ocean Territory",
    phone: [246],
    continent: "AS",
    capital: "Diego Garcia",
    currency: ["USD"],
    languages: ["en"]
  },
  IQ: {
    name: "Iraq",
    native: "\u0627\u0644\u0639\u0631\u0627\u0642",
    phone: [964],
    continent: "AS",
    capital: "Baghdad",
    currency: ["IQD"],
    languages: ["ar", "ku"]
  },
  IR: {
    name: "Iran",
    native: "\u0627\u06CC\u0631\u0627\u0646",
    phone: [98],
    continent: "AS",
    capital: "Tehran",
    currency: ["IRR"],
    languages: ["fa"]
  },
  IS: {
    name: "Iceland",
    native: "\xCDsland",
    phone: [354],
    continent: "EU",
    capital: "Reykjavik",
    currency: ["ISK"],
    languages: ["is"]
  },
  IT: {
    name: "Italy",
    native: "Italia",
    phone: [39],
    continent: "EU",
    capital: "Rome",
    currency: ["EUR"],
    languages: ["it"]
  },
  JE: {
    name: "Jersey",
    native: "Jersey",
    phone: [44],
    continent: "EU",
    capital: "Saint Helier",
    currency: ["GBP"],
    languages: ["en", "fr"]
  },
  JM: {
    name: "Jamaica",
    native: "Jamaica",
    phone: [1876],
    continent: "NA",
    capital: "Kingston",
    currency: ["JMD"],
    languages: ["en"]
  },
  JO: {
    name: "Jordan",
    native: "\u0627\u0644\u0623\u0631\u062F\u0646",
    phone: [962],
    continent: "AS",
    capital: "Amman",
    currency: ["JOD"],
    languages: ["ar"]
  },
  JP: {
    name: "Japan",
    native: "\u65E5\u672C",
    phone: [81],
    continent: "AS",
    capital: "Tokyo",
    currency: ["JPY"],
    languages: ["ja"]
  },
  KE: {
    name: "Kenya",
    native: "Kenya",
    phone: [254],
    continent: "AF",
    capital: "Nairobi",
    currency: ["KES"],
    languages: ["en", "sw"]
  },
  KG: {
    name: "Kyrgyzstan",
    native: "\u041A\u044B\u0440\u0433\u044B\u0437\u0441\u0442\u0430\u043D",
    phone: [996],
    continent: "AS",
    capital: "Bishkek",
    currency: ["KGS"],
    languages: ["ky", "ru"]
  },
  KH: {
    name: "Cambodia",
    native: "K\xE2mp\u016Dch\xE9a",
    phone: [855],
    continent: "AS",
    capital: "Phnom Penh",
    currency: ["KHR"],
    languages: ["km"]
  },
  KI: {
    name: "Kiribati",
    native: "Kiribati",
    phone: [686],
    continent: "OC",
    capital: "South Tarawa",
    currency: ["AUD"],
    languages: ["en"]
  },
  KM: {
    name: "Comoros",
    native: "Komori",
    phone: [269],
    continent: "AF",
    capital: "Moroni",
    currency: ["KMF"],
    languages: ["ar", "fr"]
  },
  KN: {
    name: "Saint Kitts and Nevis",
    native: "Saint Kitts and Nevis",
    phone: [1869],
    continent: "NA",
    capital: "Basseterre",
    currency: ["XCD"],
    languages: ["en"]
  },
  KP: {
    name: "North Korea",
    native: "\uBD81\uD55C",
    phone: [850],
    continent: "AS",
    capital: "Pyongyang",
    currency: ["KPW"],
    languages: ["ko"]
  },
  KR: {
    name: "South Korea",
    native: "\uB300\uD55C\uBBFC\uAD6D",
    phone: [82],
    continent: "AS",
    capital: "Seoul",
    currency: ["KRW"],
    languages: ["ko"]
  },
  KW: {
    name: "Kuwait",
    native: "\u0627\u0644\u0643\u0648\u064A\u062A",
    phone: [965],
    continent: "AS",
    capital: "Kuwait City",
    currency: ["KWD"],
    languages: ["ar"]
  },
  KY: {
    name: "Cayman Islands",
    native: "Cayman Islands",
    phone: [1345],
    continent: "NA",
    capital: "George Town",
    currency: ["KYD"],
    languages: ["en"]
  },
  KZ: {
    name: "Kazakhstan",
    native: "\u049A\u0430\u0437\u0430\u049B\u0441\u0442\u0430\u043D",
    phone: [7],
    continent: "AS",
    continents: ["AS", "EU"],
    capital: "Astana",
    currency: ["KZT"],
    languages: ["kk", "ru"]
  },
  LA: {
    name: "Laos",
    native: "\u0EAA\u0E9B\u0E9B\u0EA5\u0EB2\u0EA7",
    phone: [856],
    continent: "AS",
    capital: "Vientiane",
    currency: ["LAK"],
    languages: ["lo"]
  },
  LB: {
    name: "Lebanon",
    native: "\u0644\u0628\u0646\u0627\u0646",
    phone: [961],
    continent: "AS",
    capital: "Beirut",
    currency: ["LBP"],
    languages: ["ar", "fr"]
  },
  LC: {
    name: "Saint Lucia",
    native: "Saint Lucia",
    phone: [1758],
    continent: "NA",
    capital: "Castries",
    currency: ["XCD"],
    languages: ["en"]
  },
  LI: {
    name: "Liechtenstein",
    native: "Liechtenstein",
    phone: [423],
    continent: "EU",
    capital: "Vaduz",
    currency: ["CHF"],
    languages: ["de"]
  },
  LK: {
    name: "Sri Lanka",
    native: "\u015Br\u012B la\u1E43k\u0101va",
    phone: [94],
    continent: "AS",
    capital: "Colombo",
    currency: ["LKR"],
    languages: ["si", "ta"]
  },
  LR: {
    name: "Liberia",
    native: "Liberia",
    phone: [231],
    continent: "AF",
    capital: "Monrovia",
    currency: ["LRD"],
    languages: ["en"]
  },
  LS: {
    name: "Lesotho",
    native: "Lesotho",
    phone: [266],
    continent: "AF",
    capital: "Maseru",
    currency: ["LSL", "ZAR"],
    languages: ["en", "st"]
  },
  LT: {
    name: "Lithuania",
    native: "Lietuva",
    phone: [370],
    continent: "EU",
    capital: "Vilnius",
    currency: ["EUR"],
    languages: ["lt"]
  },
  LU: {
    name: "Luxembourg",
    native: "Luxembourg",
    phone: [352],
    continent: "EU",
    capital: "Luxembourg",
    currency: ["EUR"],
    languages: ["fr", "de", "lb"]
  },
  LV: {
    name: "Latvia",
    native: "Latvija",
    phone: [371],
    continent: "EU",
    capital: "Riga",
    currency: ["EUR"],
    languages: ["lv"]
  },
  LY: {
    name: "Libya",
    native: "\u200F\u0644\u064A\u0628\u064A\u0627",
    phone: [218],
    continent: "AF",
    capital: "Tripoli",
    currency: ["LYD"],
    languages: ["ar"]
  },
  MA: {
    name: "Morocco",
    native: "\u0627\u0644\u0645\u063A\u0631\u0628",
    phone: [212],
    continent: "AF",
    capital: "Rabat",
    currency: ["MAD"],
    languages: ["ar"]
  },
  MC: {
    name: "Monaco",
    native: "Monaco",
    phone: [377],
    continent: "EU",
    capital: "Monaco",
    currency: ["EUR"],
    languages: ["fr"]
  },
  MD: {
    name: "Moldova",
    native: "Moldova",
    phone: [373],
    continent: "EU",
    capital: "Chi\u0219in\u0103u",
    currency: ["MDL"],
    languages: ["ro"]
  },
  ME: {
    name: "Montenegro",
    native: "\u0426\u0440\u043D\u0430 \u0413\u043E\u0440\u0430",
    phone: [382],
    continent: "EU",
    capital: "Podgorica",
    currency: ["EUR"],
    languages: ["sr", "bs", "sq", "hr"]
  },
  MF: {
    name: "Saint Martin",
    native: "Saint-Martin",
    phone: [590],
    continent: "NA",
    capital: "Marigot",
    currency: ["EUR"],
    languages: ["en", "fr", "nl"]
  },
  MG: {
    name: "Madagascar",
    native: "Madagasikara",
    phone: [261],
    continent: "AF",
    capital: "Antananarivo",
    currency: ["MGA"],
    languages: ["fr", "mg"]
  },
  MH: {
    name: "Marshall Islands",
    native: "M\u0327aje\u013C",
    phone: [692],
    continent: "OC",
    capital: "Majuro",
    currency: ["USD"],
    languages: ["en", "mh"]
  },
  MK: {
    name: "North Macedonia",
    native: "\u0421\u0435\u0432\u0435\u0440\u043D\u0430 \u041C\u0430\u043A\u0435\u0434\u043E\u043D\u0438\u0458\u0430",
    phone: [389],
    continent: "EU",
    capital: "Skopje",
    currency: ["MKD"],
    languages: ["mk"]
  },
  ML: {
    name: "Mali",
    native: "Mali",
    phone: [223],
    continent: "AF",
    capital: "Bamako",
    currency: ["XOF"],
    languages: ["fr"]
  },
  MM: {
    name: "Myanmar (Burma)",
    native: "\u1019\u103C\u1014\u103A\u1019\u102C",
    phone: [95],
    continent: "AS",
    capital: "Naypyidaw",
    currency: ["MMK"],
    languages: ["my"]
  },
  MN: {
    name: "Mongolia",
    native: "\u041C\u043E\u043D\u0433\u043E\u043B \u0443\u043B\u0441",
    phone: [976],
    continent: "AS",
    capital: "Ulan Bator",
    currency: ["MNT"],
    languages: ["mn"]
  },
  MO: {
    name: "Macao",
    native: "\u6FB3\u9580",
    phone: [853],
    continent: "AS",
    capital: "",
    currency: ["MOP"],
    languages: ["zh", "pt"]
  },
  MP: {
    name: "Northern Mariana Islands",
    native: "Northern Mariana Islands",
    phone: [1670],
    continent: "OC",
    capital: "Saipan",
    currency: ["USD"],
    languages: ["en", "ch"]
  },
  MQ: {
    name: "Martinique",
    native: "Martinique",
    phone: [596],
    continent: "NA",
    capital: "Fort-de-France",
    currency: ["EUR"],
    languages: ["fr"]
  },
  MR: {
    name: "Mauritania",
    native: "\u0645\u0648\u0631\u064A\u062A\u0627\u0646\u064A\u0627",
    phone: [222],
    continent: "AF",
    capital: "Nouakchott",
    currency: ["MRU"],
    languages: ["ar"]
  },
  MS: {
    name: "Montserrat",
    native: "Montserrat",
    phone: [1664],
    continent: "NA",
    capital: "Plymouth",
    currency: ["XCD"],
    languages: ["en"]
  },
  MT: {
    name: "Malta",
    native: "Malta",
    phone: [356],
    continent: "EU",
    capital: "Valletta",
    currency: ["EUR"],
    languages: ["mt", "en"]
  },
  MU: {
    name: "Mauritius",
    native: "Maurice",
    phone: [230],
    continent: "AF",
    capital: "Port Louis",
    currency: ["MUR"],
    languages: ["en"]
  },
  MV: {
    name: "Maldives",
    native: "Maldives",
    phone: [960],
    continent: "AS",
    capital: "Mal\xE9",
    currency: ["MVR"],
    languages: ["dv"]
  },
  MW: {
    name: "Malawi",
    native: "Malawi",
    phone: [265],
    continent: "AF",
    capital: "Lilongwe",
    currency: ["MWK"],
    languages: ["en", "ny"]
  },
  MX: {
    name: "Mexico",
    native: "M\xE9xico",
    phone: [52],
    continent: "NA",
    capital: "Mexico City",
    currency: ["MXN"],
    languages: ["es"]
  },
  MY: {
    name: "Malaysia",
    native: "Malaysia",
    phone: [60],
    continent: "AS",
    capital: "Kuala Lumpur",
    currency: ["MYR"],
    languages: ["ms"]
  },
  MZ: {
    name: "Mozambique",
    native: "Mo\xE7ambique",
    phone: [258],
    continent: "AF",
    capital: "Maputo",
    currency: ["MZN"],
    languages: ["pt"]
  },
  NA: {
    name: "Namibia",
    native: "Namibia",
    phone: [264],
    continent: "AF",
    capital: "Windhoek",
    currency: ["NAD", "ZAR"],
    languages: ["en", "af"]
  },
  NC: {
    name: "New Caledonia",
    native: "Nouvelle-Cal\xE9donie",
    phone: [687],
    continent: "OC",
    capital: "Noum\xE9a",
    currency: ["XPF"],
    languages: ["fr"]
  },
  NE: {
    name: "Niger",
    native: "Niger",
    phone: [227],
    continent: "AF",
    capital: "Niamey",
    currency: ["XOF"],
    languages: ["fr"]
  },
  NF: {
    name: "Norfolk Island",
    native: "Norfolk Island",
    phone: [672],
    continent: "OC",
    capital: "Kingston",
    currency: ["AUD"],
    languages: ["en"]
  },
  NG: {
    name: "Nigeria",
    native: "Nigeria",
    phone: [234],
    continent: "AF",
    capital: "Abuja",
    currency: ["NGN"],
    languages: ["en"]
  },
  NI: {
    name: "Nicaragua",
    native: "Nicaragua",
    phone: [505],
    continent: "NA",
    capital: "Managua",
    currency: ["NIO"],
    languages: ["es"]
  },
  NL: {
    name: "Netherlands",
    native: "Nederland",
    phone: [31],
    continent: "EU",
    capital: "Amsterdam",
    currency: ["EUR"],
    languages: ["nl"]
  },
  NO: {
    name: "Norway",
    native: "Norge",
    phone: [47],
    continent: "EU",
    capital: "Oslo",
    currency: ["NOK"],
    languages: ["no", "nb", "nn"]
  },
  NP: {
    name: "Nepal",
    native: "\u0928\u0947\u092A\u093E\u0932",
    phone: [977],
    continent: "AS",
    capital: "Kathmandu",
    currency: ["NPR"],
    languages: ["ne"]
  },
  NR: {
    name: "Nauru",
    native: "Nauru",
    phone: [674],
    continent: "OC",
    capital: "Yaren",
    currency: ["AUD"],
    languages: ["en", "na"]
  },
  NU: {
    name: "Niue",
    native: "Niu\u0113",
    phone: [683],
    continent: "OC",
    capital: "Alofi",
    currency: ["NZD"],
    languages: ["en"]
  },
  NZ: {
    name: "New Zealand",
    native: "New Zealand",
    phone: [64],
    continent: "OC",
    capital: "Wellington",
    currency: ["NZD"],
    languages: ["en", "mi"]
  },
  OM: {
    name: "Oman",
    native: "\u0639\u0645\u0627\u0646",
    phone: [968],
    continent: "AS",
    capital: "Muscat",
    currency: ["OMR"],
    languages: ["ar"]
  },
  PA: {
    name: "Panama",
    native: "Panam\xE1",
    phone: [507],
    continent: "NA",
    capital: "Panama City",
    currency: ["PAB", "USD"],
    languages: ["es"]
  },
  PE: {
    name: "Peru",
    native: "Per\xFA",
    phone: [51],
    continent: "SA",
    capital: "Lima",
    currency: ["PEN"],
    languages: ["es"]
  },
  PF: {
    name: "French Polynesia",
    native: "Polyn\xE9sie fran\xE7aise",
    phone: [689],
    continent: "OC",
    capital: "Papeet\u0113",
    currency: ["XPF"],
    languages: ["fr"]
  },
  PG: {
    name: "Papua New Guinea",
    native: "Papua Niugini",
    phone: [675],
    continent: "OC",
    capital: "Port Moresby",
    currency: ["PGK"],
    languages: ["en"]
  },
  PH: {
    name: "Philippines",
    native: "Pilipinas",
    phone: [63],
    continent: "AS",
    capital: "Manila",
    currency: ["PHP"],
    languages: ["en"]
  },
  PK: {
    name: "Pakistan",
    native: "Pakistan",
    phone: [92],
    continent: "AS",
    capital: "Islamabad",
    currency: ["PKR"],
    languages: ["en", "ur"]
  },
  PL: {
    name: "Poland",
    native: "Polska",
    phone: [48],
    continent: "EU",
    capital: "Warsaw",
    currency: ["PLN"],
    languages: ["pl"]
  },
  PM: {
    name: "Saint Pierre and Miquelon",
    native: "Saint-Pierre-et-Miquelon",
    phone: [508],
    continent: "NA",
    capital: "Saint-Pierre",
    currency: ["EUR"],
    languages: ["fr"]
  },
  PN: {
    name: "Pitcairn Islands",
    native: "Pitcairn Islands",
    phone: [64],
    continent: "OC",
    capital: "Adamstown",
    currency: ["NZD"],
    languages: ["en"]
  },
  PR: {
    name: "Puerto Rico",
    native: "Puerto Rico",
    phone: [1787, 1939],
    continent: "NA",
    capital: "San Juan",
    currency: ["USD"],
    languages: ["es", "en"]
  },
  PS: {
    name: "Palestine",
    native: "\u0641\u0644\u0633\u0637\u064A\u0646",
    phone: [970],
    continent: "AS",
    capital: "Ramallah",
    currency: ["ILS"],
    languages: ["ar"]
  },
  PT: {
    name: "Portugal",
    native: "Portugal",
    phone: [351],
    continent: "EU",
    capital: "Lisbon",
    currency: ["EUR"],
    languages: ["pt"]
  },
  PW: {
    name: "Palau",
    native: "Palau",
    phone: [680],
    continent: "OC",
    capital: "Ngerulmud",
    currency: ["USD"],
    languages: ["en"]
  },
  PY: {
    name: "Paraguay",
    native: "Paraguay",
    phone: [595],
    continent: "SA",
    capital: "Asunci\xF3n",
    currency: ["PYG"],
    languages: ["es", "gn"]
  },
  QA: {
    name: "Qatar",
    native: "\u0642\u0637\u0631",
    phone: [974],
    continent: "AS",
    capital: "Doha",
    currency: ["QAR"],
    languages: ["ar"]
  },
  RE: {
    name: "Reunion",
    native: "La R\xE9union",
    phone: [262],
    continent: "AF",
    capital: "Saint-Denis",
    currency: ["EUR"],
    languages: ["fr"]
  },
  RO: {
    name: "Romania",
    native: "Rom\xE2nia",
    phone: [40],
    continent: "EU",
    capital: "Bucharest",
    currency: ["RON"],
    languages: ["ro"]
  },
  RS: {
    name: "Serbia",
    native: "\u0421\u0440\u0431\u0438\u0458\u0430",
    phone: [381],
    continent: "EU",
    capital: "Belgrade",
    currency: ["RSD"],
    languages: ["sr"]
  },
  RU: {
    name: "Russia",
    native: "\u0420\u043E\u0441\u0441\u0438\u044F",
    phone: [7],
    continent: "AS",
    continents: ["AS", "EU"],
    capital: "Moscow",
    currency: ["RUB"],
    languages: ["ru"]
  },
  RW: {
    name: "Rwanda",
    native: "Rwanda",
    phone: [250],
    continent: "AF",
    capital: "Kigali",
    currency: ["RWF"],
    languages: ["rw", "en", "fr"]
  },
  SA: {
    name: "Saudi Arabia",
    native: "\u0627\u0644\u0639\u0631\u0628\u064A\u0629 \u0627\u0644\u0633\u0639\u0648\u062F\u064A\u0629",
    phone: [966],
    continent: "AS",
    capital: "Riyadh",
    currency: ["SAR"],
    languages: ["ar"]
  },
  SB: {
    name: "Solomon Islands",
    native: "Solomon Islands",
    phone: [677],
    continent: "OC",
    capital: "Honiara",
    currency: ["SBD"],
    languages: ["en"]
  },
  SC: {
    name: "Seychelles",
    native: "Seychelles",
    phone: [248],
    continent: "AF",
    capital: "Victoria",
    currency: ["SCR"],
    languages: ["fr", "en"]
  },
  SD: {
    name: "Sudan",
    native: "\u0627\u0644\u0633\u0648\u062F\u0627\u0646",
    phone: [249],
    continent: "AF",
    capital: "Khartoum",
    currency: ["SDG"],
    languages: ["ar", "en"]
  },
  SE: {
    name: "Sweden",
    native: "Sverige",
    phone: [46],
    continent: "EU",
    capital: "Stockholm",
    currency: ["SEK"],
    languages: ["sv"]
  },
  SG: {
    name: "Singapore",
    native: "Singapore",
    phone: [65],
    continent: "AS",
    capital: "Singapore",
    currency: ["SGD"],
    languages: ["en", "ms", "ta", "zh"]
  },
  SH: {
    name: "Saint Helena",
    native: "Saint Helena",
    phone: [290],
    continent: "AF",
    capital: "Jamestown",
    currency: ["SHP"],
    languages: ["en"]
  },
  SI: {
    name: "Slovenia",
    native: "Slovenija",
    phone: [386],
    continent: "EU",
    capital: "Ljubljana",
    currency: ["EUR"],
    languages: ["sl"]
  },
  SJ: {
    name: "Svalbard and Jan Mayen",
    native: "Svalbard og Jan Mayen",
    phone: [4779],
    continent: "EU",
    capital: "Longyearbyen",
    currency: ["NOK"],
    languages: ["no"]
  },
  SK: {
    name: "Slovakia",
    native: "Slovensko",
    phone: [421],
    continent: "EU",
    capital: "Bratislava",
    currency: ["EUR"],
    languages: ["sk"]
  },
  SL: {
    name: "Sierra Leone",
    native: "Sierra Leone",
    phone: [232],
    continent: "AF",
    capital: "Freetown",
    currency: ["SLL"],
    languages: ["en"]
  },
  SM: {
    name: "San Marino",
    native: "San Marino",
    phone: [378],
    continent: "EU",
    capital: "City of San Marino",
    currency: ["EUR"],
    languages: ["it"]
  },
  SN: {
    name: "Senegal",
    native: "S\xE9n\xE9gal",
    phone: [221],
    continent: "AF",
    capital: "Dakar",
    currency: ["XOF"],
    languages: ["fr"]
  },
  SO: {
    name: "Somalia",
    native: "Soomaaliya",
    phone: [252],
    continent: "AF",
    capital: "Mogadishu",
    currency: ["SOS"],
    languages: ["so", "ar"]
  },
  SR: {
    name: "Suriname",
    native: "Suriname",
    phone: [597],
    continent: "SA",
    capital: "Paramaribo",
    currency: ["SRD"],
    languages: ["nl"]
  },
  SS: {
    name: "South Sudan",
    native: "South Sudan",
    phone: [211],
    continent: "AF",
    capital: "Juba",
    currency: ["SSP"],
    languages: ["en"]
  },
  ST: {
    name: "Sao Tome and Principe",
    native: "S\xE3o Tom\xE9 e Pr\xEDncipe",
    phone: [239],
    continent: "AF",
    capital: "S\xE3o Tom\xE9",
    currency: ["STN"],
    languages: ["pt"]
  },
  SV: {
    name: "El Salvador",
    native: "El Salvador",
    phone: [503],
    continent: "NA",
    capital: "San Salvador",
    currency: ["SVC", "USD"],
    languages: ["es"]
  },
  SX: {
    name: "Sint Maarten",
    native: "Sint Maarten",
    phone: [1721],
    continent: "NA",
    capital: "Philipsburg",
    currency: ["ANG"],
    languages: ["nl", "en"]
  },
  SY: {
    name: "Syria",
    native: "\u0633\u0648\u0631\u064A\u0627",
    phone: [963],
    continent: "AS",
    capital: "Damascus",
    currency: ["SYP"],
    languages: ["ar"]
  },
  SZ: {
    name: "Eswatini",
    native: "Eswatini",
    phone: [268],
    continent: "AF",
    capital: "Lobamba",
    currency: ["SZL"],
    languages: ["en", "ss"]
  },
  TC: {
    name: "Turks and Caicos Islands",
    native: "Turks and Caicos Islands",
    phone: [1649],
    continent: "NA",
    capital: "Cockburn Town",
    currency: ["USD"],
    languages: ["en"]
  },
  TD: {
    name: "Chad",
    native: "Tchad",
    phone: [235],
    continent: "AF",
    capital: "N'Djamena",
    currency: ["XAF"],
    languages: ["fr", "ar"]
  },
  TF: {
    name: "French Southern Territories",
    native: "Territoire des Terres australes et antarctiques fr",
    phone: [262],
    continent: "AN",
    capital: "Port-aux-Fran\xE7ais",
    currency: ["EUR"],
    languages: ["fr"]
  },
  TG: {
    name: "Togo",
    native: "Togo",
    phone: [228],
    continent: "AF",
    capital: "Lom\xE9",
    currency: ["XOF"],
    languages: ["fr"]
  },
  TH: {
    name: "Thailand",
    native: "\u0E1B\u0E23\u0E30\u0E40\u0E17\u0E28\u0E44\u0E17\u0E22",
    phone: [66],
    continent: "AS",
    capital: "Bangkok",
    currency: ["THB"],
    languages: ["th"]
  },
  TJ: {
    name: "Tajikistan",
    native: "\u0422\u043E\u04B7\u0438\u043A\u0438\u0441\u0442\u043E\u043D",
    phone: [992],
    continent: "AS",
    capital: "Dushanbe",
    currency: ["TJS"],
    languages: ["tg", "ru"]
  },
  TK: {
    name: "Tokelau",
    native: "Tokelau",
    phone: [690],
    continent: "OC",
    capital: "Fakaofo",
    currency: ["NZD"],
    languages: ["en"]
  },
  TL: {
    name: "East Timor",
    native: "Timor-Leste",
    phone: [670],
    continent: "OC",
    capital: "Dili",
    currency: ["USD"],
    languages: ["pt"]
  },
  TM: {
    name: "Turkmenistan",
    native: "T\xFCrkmenistan",
    phone: [993],
    continent: "AS",
    capital: "Ashgabat",
    currency: ["TMT"],
    languages: ["tk", "ru"]
  },
  TN: {
    name: "Tunisia",
    native: "\u062A\u0648\u0646\u0633",
    phone: [216],
    continent: "AF",
    capital: "Tunis",
    currency: ["TND"],
    languages: ["ar"]
  },
  TO: {
    name: "Tonga",
    native: "Tonga",
    phone: [676],
    continent: "OC",
    capital: "Nuku'alofa",
    currency: ["TOP"],
    languages: ["en", "to"]
  },
  TR: {
    name: "Turkey",
    native: "T\xFCrkiye",
    phone: [90],
    continent: "AS",
    continents: ["AS", "EU"],
    capital: "Ankara",
    currency: ["TRY"],
    languages: ["tr"]
  },
  TT: {
    name: "Trinidad and Tobago",
    native: "Trinidad and Tobago",
    phone: [1868],
    continent: "NA",
    capital: "Port of Spain",
    currency: ["TTD"],
    languages: ["en"]
  },
  TV: {
    name: "Tuvalu",
    native: "Tuvalu",
    phone: [688],
    continent: "OC",
    capital: "Funafuti",
    currency: ["AUD"],
    languages: ["en"]
  },
  TW: {
    name: "Taiwan",
    native: "\u81FA\u7063",
    phone: [886],
    continent: "AS",
    capital: "Taipei",
    currency: ["TWD"],
    languages: ["zh"]
  },
  TZ: {
    name: "Tanzania",
    native: "Tanzania",
    phone: [255],
    continent: "AF",
    capital: "Dodoma",
    currency: ["TZS"],
    languages: ["sw", "en"]
  },
  UA: {
    name: "Ukraine",
    native: "\u0423\u043A\u0440\u0430\u0457\u043D\u0430",
    phone: [380],
    continent: "EU",
    capital: "Kyiv",
    currency: ["UAH"],
    languages: ["uk"]
  },
  UG: {
    name: "Uganda",
    native: "Uganda",
    phone: [256],
    continent: "AF",
    capital: "Kampala",
    currency: ["UGX"],
    languages: ["en", "sw"]
  },
  UM: {
    name: "U.S. Minor Outlying Islands",
    native: "United States Minor Outlying Islands",
    phone: [1],
    continent: "OC",
    capital: "",
    currency: ["USD"],
    languages: ["en"]
  },
  US: {
    name: "United States",
    native: "United States",
    phone: [1],
    continent: "NA",
    capital: "Washington D.C.",
    currency: ["USD", "USN", "USS"],
    languages: ["en"]
  },
  UY: {
    name: "Uruguay",
    native: "Uruguay",
    phone: [598],
    continent: "SA",
    capital: "Montevideo",
    currency: ["UYI", "UYU"],
    languages: ["es"]
  },
  UZ: {
    name: "Uzbekistan",
    native: "O'zbekiston",
    phone: [998],
    continent: "AS",
    capital: "Tashkent",
    currency: ["UZS"],
    languages: ["uz", "ru"]
  },
  VA: {
    name: "Vatican City",
    native: "Vaticano",
    phone: [379],
    continent: "EU",
    capital: "Vatican City",
    currency: ["EUR"],
    languages: ["it", "la"]
  },
  VC: {
    name: "Saint Vincent and the Grenadines",
    native: "Saint Vincent and the Grenadines",
    phone: [1784],
    continent: "NA",
    capital: "Kingstown",
    currency: ["XCD"],
    languages: ["en"]
  },
  VE: {
    name: "Venezuela",
    native: "Venezuela",
    phone: [58],
    continent: "SA",
    capital: "Caracas",
    currency: ["VES"],
    languages: ["es"]
  },
  VG: {
    name: "British Virgin Islands",
    native: "British Virgin Islands",
    phone: [1284],
    continent: "NA",
    capital: "Road Town",
    currency: ["USD"],
    languages: ["en"]
  },
  VI: {
    name: "U.S. Virgin Islands",
    native: "United States Virgin Islands",
    phone: [1340],
    continent: "NA",
    capital: "Charlotte Amalie",
    currency: ["USD"],
    languages: ["en"]
  },
  VN: {
    name: "Vietnam",
    native: "Vi\u1EC7t Nam",
    phone: [84],
    continent: "AS",
    capital: "Hanoi",
    currency: ["VND"],
    languages: ["vi"]
  },
  VU: {
    name: "Vanuatu",
    native: "Vanuatu",
    phone: [678],
    continent: "OC",
    capital: "Port Vila",
    currency: ["VUV"],
    languages: ["bi", "en", "fr"]
  },
  WF: {
    name: "Wallis and Futuna",
    native: "Wallis et Futuna",
    phone: [681],
    continent: "OC",
    capital: "Mata-Utu",
    currency: ["XPF"],
    languages: ["fr"]
  },
  WS: {
    name: "Samoa",
    native: "Samoa",
    phone: [685],
    continent: "OC",
    capital: "Apia",
    currency: ["WST"],
    languages: ["sm", "en"]
  },
  XK: {
    name: "Kosovo",
    native: "Republika e Kosov\xEBs",
    phone: [377, 381, 383, 386],
    continent: "EU",
    capital: "Pristina",
    currency: ["EUR"],
    languages: ["sq", "sr"],
    userAssigned: !0
  },
  YE: {
    name: "Yemen",
    native: "\u0627\u0644\u064A\u064E\u0645\u064E\u0646",
    phone: [967],
    continent: "AS",
    capital: "Sana'a",
    currency: ["YER"],
    languages: ["ar"]
  },
  YT: {
    name: "Mayotte",
    native: "Mayotte",
    phone: [262],
    continent: "AF",
    capital: "Mamoudzou",
    currency: ["EUR"],
    languages: ["fr"]
  },
  ZA: {
    name: "South Africa",
    native: "South Africa",
    phone: [27],
    continent: "AF",
    capital: "Pretoria",
    currency: ["ZAR"],
    languages: ["af", "en", "nr", "st", "ss", "tn", "ts", "ve", "xh", "zu"]
  },
  ZM: {
    name: "Zambia",
    native: "Zambia",
    phone: [260],
    continent: "AF",
    capital: "Lusaka",
    currency: ["ZMW"],
    languages: ["en"]
  },
  ZW: {
    name: "Zimbabwe",
    native: "Zimbabwe",
    phone: [263],
    continent: "AF",
    capital: "Harare",
    currency: ["USD", "ZAR", "BWP", "GBP", "AUD", "CNY", "INR", "JPY"],
    languages: ["en", "sn", "nd"]
  }
};
var s = {
  aa: {
    name: "Afar",
    native: "Afar"
  },
  ab: {
    name: "Abkhazian",
    native: "\u0410\u04A7\u0441\u0443\u0430"
  },
  af: {
    name: "Afrikaans",
    native: "Afrikaans"
  },
  ak: {
    name: "Akan",
    native: "Akana"
  },
  am: {
    name: "Amharic",
    native: "\u12A0\u121B\u122D\u129B"
  },
  an: {
    name: "Aragonese",
    native: "Aragon\xE9s"
  },
  ar: {
    name: "Arabic",
    native: "\u0627\u0644\u0639\u0631\u0628\u064A\u0629",
    rtl: 1
  },
  as: {
    name: "Assamese",
    native: "\u0985\u09B8\u09AE\u09C0\u09AF\u09BC\u09BE"
  },
  av: {
    name: "Avar",
    native: "\u0410\u0432\u0430\u0440"
  },
  ay: {
    name: "Aymara",
    native: "Aymar"
  },
  az: {
    name: "Azerbaijani",
    native: "Az\u0259rbaycanca / \u0622\u0630\u0631\u0628\u0627\u064A\u062C\u0627\u0646"
  },
  ba: {
    name: "Bashkir",
    native: "\u0411\u0430\u0448\u04A1\u043E\u0440\u0442"
  },
  be: {
    name: "Belarusian",
    native: "\u0411\u0435\u043B\u0430\u0440\u0443\u0441\u043A\u0430\u044F"
  },
  bg: {
    name: "Bulgarian",
    native: "\u0411\u044A\u043B\u0433\u0430\u0440\u0441\u043A\u0438"
  },
  bh: {
    name: "Bihari",
    native: "\u092D\u094B\u091C\u092A\u0941\u0930\u0940"
  },
  bi: {
    name: "Bislama",
    native: "Bislama"
  },
  bm: {
    name: "Bambara",
    native: "Bamanankan"
  },
  bn: {
    name: "Bengali",
    native: "\u09AC\u09BE\u0982\u09B2\u09BE"
  },
  bo: {
    name: "Tibetan",
    native: "\u0F56\u0F7C\u0F51\u0F0B\u0F61\u0F72\u0F42 / Bod skad"
  },
  br: {
    name: "Breton",
    native: "Brezhoneg"
  },
  bs: {
    name: "Bosnian",
    native: "Bosanski"
  },
  ca: {
    name: "Catalan",
    native: "Catal\xE0"
  },
  ce: {
    name: "Chechen",
    native: "\u041D\u043E\u0445\u0447\u0438\u0439\u043D"
  },
  ch: {
    name: "Chamorro",
    native: "Chamoru"
  },
  co: {
    name: "Corsican",
    native: "Corsu"
  },
  cr: {
    name: "Cree",
    native: "Nehiyaw"
  },
  cs: {
    name: "Czech",
    native: "\u010Ce\u0161tina"
  },
  cu: {
    name: "Old Church Slavonic / Old Bulgarian",
    native: "\u0441\u043B\u043E\u0432\u0463\u043D\u044C\u0441\u043A\u044A / slov\u011Bn\u012Dsk\u016D"
  },
  cv: {
    name: "Chuvash",
    native: "\u0427\u0103\u0432\u0430\u0448"
  },
  cy: {
    name: "Welsh",
    native: "Cymraeg"
  },
  da: {
    name: "Danish",
    native: "Dansk"
  },
  de: {
    name: "German",
    native: "Deutsch"
  },
  dv: {
    name: "Divehi",
    native: "\u078B\u07A8\u0788\u07AC\u0780\u07A8\u0784\u07A6\u0790\u07B0",
    rtl: 1
  },
  dz: {
    name: "Dzongkha",
    native: "\u0F47\u0F7C\u0F44\u0F0B\u0F41"
  },
  ee: {
    name: "Ewe",
    native: "\u0190\u028B\u025B"
  },
  el: {
    name: "Greek",
    native: "\u0395\u03BB\u03BB\u03B7\u03BD\u03B9\u03BA\u03AC"
  },
  en: {
    name: "English",
    native: "English"
  },
  eo: {
    name: "Esperanto",
    native: "Esperanto"
  },
  es: {
    name: "Spanish",
    native: "Espa\xF1ol"
  },
  et: {
    name: "Estonian",
    native: "Eesti"
  },
  eu: {
    name: "Basque",
    native: "Euskara"
  },
  fa: {
    name: "Persian",
    native: "\u0641\u0627\u0631\u0633\u06CC",
    rtl: 1
  },
  ff: {
    name: "Peul",
    native: "Fulfulde"
  },
  fi: {
    name: "Finnish",
    native: "Suomi"
  },
  fj: {
    name: "Fijian",
    native: "Na Vosa Vakaviti"
  },
  fo: {
    name: "Faroese",
    native: "F\xF8royskt"
  },
  fr: {
    name: "French",
    native: "Fran\xE7ais"
  },
  fy: {
    name: "West Frisian",
    native: "Frysk"
  },
  ga: {
    name: "Irish",
    native: "Gaeilge"
  },
  gd: {
    name: "Scottish Gaelic",
    native: "G\xE0idhlig"
  },
  gl: {
    name: "Galician",
    native: "Galego"
  },
  gn: {
    name: "Guarani",
    native: "Ava\xF1e'\u1EBD"
  },
  gu: {
    name: "Gujarati",
    native: "\u0A97\u0AC1\u0A9C\u0AB0\u0ABE\u0AA4\u0AC0"
  },
  gv: {
    name: "Manx",
    native: "Gaelg"
  },
  ha: {
    name: "Hausa",
    native: "\u0647\u064E\u0648\u064F\u0633\u064E",
    rtl: 1
  },
  he: {
    name: "Hebrew",
    native: "\u05E2\u05D1\u05E8\u05D9\u05EA",
    rtl: 1
  },
  hi: {
    name: "Hindi",
    native: "\u0939\u093F\u0928\u094D\u0926\u0940"
  },
  ho: {
    name: "Hiri Motu",
    native: "Hiri Motu"
  },
  hr: {
    name: "Croatian",
    native: "Hrvatski"
  },
  ht: {
    name: "Haitian",
    native: "Kr\xE8yol ayisyen"
  },
  hu: {
    name: "Hungarian",
    native: "Magyar"
  },
  hy: {
    name: "Armenian",
    native: "\u0540\u0561\u0575\u0565\u0580\u0565\u0576"
  },
  hz: {
    name: "Herero",
    native: "Otsiherero"
  },
  ia: {
    name: "Interlingua",
    native: "Interlingua"
  },
  id: {
    name: "Indonesian",
    native: "Bahasa Indonesia"
  },
  ie: {
    name: "Interlingue",
    native: "Interlingue"
  },
  ig: {
    name: "Igbo",
    native: "Igbo"
  },
  ii: {
    name: "Sichuan Yi",
    native: "\uA187\uA259 / \u56DB\u5DDD\u5F5D\u8BED"
  },
  ik: {
    name: "Inupiak",
    native: "I\xF1upiak"
  },
  io: {
    name: "Ido",
    native: "Ido"
  },
  is: {
    name: "Icelandic",
    native: "\xCDslenska"
  },
  it: {
    name: "Italian",
    native: "Italiano"
  },
  iu: {
    name: "Inuktitut",
    native: "\u1403\u14C4\u1483\u144E\u1450\u1466"
  },
  ja: {
    name: "Japanese",
    native: "\u65E5\u672C\u8A9E"
  },
  jv: {
    name: "Javanese",
    native: "Basa Jawa"
  },
  ka: {
    name: "Georgian",
    native: "\u10E5\u10D0\u10E0\u10D7\u10E3\u10DA\u10D8"
  },
  kg: {
    name: "Kongo",
    native: "KiKongo"
  },
  ki: {
    name: "Kikuyu",
    native: "G\u0129k\u0169y\u0169"
  },
  kj: {
    name: "Kuanyama",
    native: "Kuanyama"
  },
  kk: {
    name: "Kazakh",
    native: "\u049A\u0430\u0437\u0430\u049B\u0448\u0430"
  },
  kl: {
    name: "Greenlandic",
    native: "Kalaallisut"
  },
  km: {
    name: "Cambodian",
    native: "\u1797\u17B6\u179F\u17B6\u1781\u17D2\u1798\u17C2\u179A"
  },
  kn: {
    name: "Kannada",
    native: "\u0C95\u0CA8\u0CCD\u0CA8\u0CA1"
  },
  ko: {
    name: "Korean",
    native: "\uD55C\uAD6D\uC5B4"
  },
  kr: {
    name: "Kanuri",
    native: "Kanuri"
  },
  ks: {
    name: "Kashmiri",
    native: "\u0915\u0936\u094D\u092E\u0940\u0930\u0940 / \u0643\u0634\u0645\u064A\u0631\u064A",
    rtl: 1
  },
  ku: {
    name: "Kurdish",
    native: "Kurd\xEE / \u0643\u0648\u0631\u062F\u06CC",
    rtl: 1
  },
  kv: {
    name: "Komi",
    native: "\u041A\u043E\u043C\u0438"
  },
  kw: {
    name: "Cornish",
    native: "Kernewek"
  },
  ky: {
    name: "Kyrgyz",
    native: "\u041A\u044B\u0440\u0433\u044B\u0437\u0447\u0430"
  },
  la: {
    name: "Latin",
    native: "Latina"
  },
  lb: {
    name: "Luxembourgish",
    native: "L\xEBtzebuergesch"
  },
  lg: {
    name: "Ganda",
    native: "Luganda"
  },
  li: {
    name: "Limburgian",
    native: "Limburgs"
  },
  ln: {
    name: "Lingala",
    native: "Ling\xE1la"
  },
  lo: {
    name: "Laotian",
    native: "\u0EA5\u0EB2\u0EA7 / Pha xa lao"
  },
  lt: {
    name: "Lithuanian",
    native: "Lietuvi\u0173"
  },
  lu: {
    name: "Luba-Katanga",
    native: "Tshiluba"
  },
  lv: {
    name: "Latvian",
    native: "Latvie\u0161u"
  },
  mg: {
    name: "Malagasy",
    native: "Malagasy"
  },
  mh: {
    name: "Marshallese",
    native: "Kajin Majel / Ebon"
  },
  mi: {
    name: "Maori",
    native: "M\u0101ori"
  },
  mk: {
    name: "Macedonian",
    native: "\u041C\u0430\u043A\u0435\u0434\u043E\u043D\u0441\u043A\u0438"
  },
  ml: {
    name: "Malayalam",
    native: "\u0D2E\u0D32\u0D2F\u0D3E\u0D33\u0D02"
  },
  mn: {
    name: "Mongolian",
    native: "\u041C\u043E\u043D\u0433\u043E\u043B"
  },
  mo: {
    name: "Moldovan",
    native: "Moldoveneasc\u0103"
  },
  mr: {
    name: "Marathi",
    native: "\u092E\u0930\u093E\u0920\u0940"
  },
  ms: {
    name: "Malay",
    native: "Bahasa Melayu"
  },
  mt: {
    name: "Maltese",
    native: "bil-Malti"
  },
  my: {
    name: "Burmese",
    native: "\u1019\u103C\u1014\u103A\u1019\u102C\u1005\u102C"
  },
  na: {
    name: "Nauruan",
    native: "Dorerin Naoero"
  },
  nb: {
    name: "Norwegian Bokm\xE5l",
    native: "Norsk bokm\xE5l"
  },
  nd: {
    name: "North Ndebele",
    native: "Sindebele"
  },
  ne: {
    name: "Nepali",
    native: "\u0928\u0947\u092A\u093E\u0932\u0940"
  },
  ng: {
    name: "Ndonga",
    native: "Oshiwambo"
  },
  nl: {
    name: "Dutch",
    native: "Nederlands"
  },
  nn: {
    name: "Norwegian Nynorsk",
    native: "Norsk nynorsk"
  },
  no: {
    name: "Norwegian",
    native: "Norsk"
  },
  nr: {
    name: "South Ndebele",
    native: "isiNdebele"
  },
  nv: {
    name: "Navajo",
    native: "Din\xE9 bizaad"
  },
  ny: {
    name: "Chichewa",
    native: "Chi-Chewa"
  },
  oc: {
    name: "Occitan",
    native: "Occitan"
  },
  oj: {
    name: "Ojibwa",
    native: "\u140A\u14C2\u1511\u14C8\u142F\u14A7\u140E\u14D0 / Anishinaabemowin"
  },
  om: {
    name: "Oromo",
    native: "Oromoo"
  },
  or: {
    name: "Oriya",
    native: "\u0B13\u0B21\u0B3C\u0B3F\u0B06"
  },
  os: {
    name: "Ossetian / Ossetic",
    native: "\u0418\u0440\u043E\u043D\u0430\u0443"
  },
  pa: {
    name: "Panjabi / Punjabi",
    native: "\u0A2A\u0A70\u0A1C\u0A3E\u0A2C\u0A40 / \u092A\u0902\u091C\u093E\u092C\u0940 / \u067E\u0646\u062C\u0627\u0628\u064A"
  },
  pi: {
    name: "Pali",
    native: "P\u0101li / \u092A\u093E\u0934\u093F"
  },
  pl: {
    name: "Polish",
    native: "Polski"
  },
  ps: {
    name: "Pashto",
    native: "\u067E\u069A\u062A\u0648",
    rtl: 1
  },
  pt: {
    name: "Portuguese",
    native: "Portugu\xEAs"
  },
  qu: {
    name: "Quechua",
    native: "Runa Simi"
  },
  rm: {
    name: "Raeto Romance",
    native: "Rumantsch"
  },
  rn: {
    name: "Kirundi",
    native: "Kirundi"
  },
  ro: {
    name: "Romanian",
    native: "Rom\xE2n\u0103"
  },
  ru: {
    name: "Russian",
    native: "\u0420\u0443\u0441\u0441\u043A\u0438\u0439"
  },
  rw: {
    name: "Rwandi",
    native: "Kinyarwandi"
  },
  sa: {
    name: "Sanskrit",
    native: "\u0938\u0902\u0938\u094D\u0915\u0943\u0924\u092E\u094D"
  },
  sc: {
    name: "Sardinian",
    native: "Sardu"
  },
  sd: {
    name: "Sindhi",
    native: "\u0938\u093F\u0928\u0927\u093F"
  },
  se: {
    name: "Northern Sami",
    native: "S\xE1megiella"
  },
  sg: {
    name: "Sango",
    native: "S\xE4ng\xF6"
  },
  sh: {
    name: "Serbo-Croatian",
    native: "Srpskohrvatski / \u0421\u0440\u043F\u0441\u043A\u043E\u0445\u0440\u0432\u0430\u0442\u0441\u043A\u0438"
  },
  si: {
    name: "Sinhalese",
    native: "\u0DC3\u0DD2\u0D82\u0DC4\u0DBD"
  },
  sk: {
    name: "Slovak",
    native: "Sloven\u010Dina"
  },
  sl: {
    name: "Slovenian",
    native: "Sloven\u0161\u010Dina"
  },
  sm: {
    name: "Samoan",
    native: "Gagana Samoa"
  },
  sn: {
    name: "Shona",
    native: "chiShona"
  },
  so: {
    name: "Somalia",
    native: "Soomaaliga"
  },
  sq: {
    name: "Albanian",
    native: "Shqip"
  },
  sr: {
    name: "Serbian",
    native: "\u0421\u0440\u043F\u0441\u043A\u0438"
  },
  ss: {
    name: "Swati",
    native: "SiSwati"
  },
  st: {
    name: "Southern Sotho",
    native: "Sesotho"
  },
  su: {
    name: "Sundanese",
    native: "Basa Sunda"
  },
  sv: {
    name: "Swedish",
    native: "Svenska"
  },
  sw: {
    name: "Swahili",
    native: "Kiswahili"
  },
  ta: {
    name: "Tamil",
    native: "\u0BA4\u0BAE\u0BBF\u0BB4\u0BCD"
  },
  te: {
    name: "Telugu",
    native: "\u0C24\u0C46\u0C32\u0C41\u0C17\u0C41"
  },
  tg: {
    name: "Tajik",
    native: "\u0422\u043E\u04B7\u0438\u043A\u04E3"
  },
  th: {
    name: "Thai",
    native: "\u0E44\u0E17\u0E22 / Phasa Thai"
  },
  ti: {
    name: "Tigrinya",
    native: "\u1275\u130D\u122D\u129B"
  },
  tk: {
    name: "Turkmen",
    native: "\u0422\u0443\u0440\u043A\u043C\u0435\u043D / \u062A\u0631\u0643\u0645\u0646"
  },
  tl: {
    name: "Tagalog / Filipino",
    native: "Tagalog"
  },
  tn: {
    name: "Tswana",
    native: "Setswana"
  },
  to: {
    name: "Tonga",
    native: "Lea Faka-Tonga"
  },
  tr: {
    name: "Turkish",
    native: "T\xFCrk\xE7e"
  },
  ts: {
    name: "Tsonga",
    native: "Xitsonga"
  },
  tt: {
    name: "Tatar",
    native: "Tatar\xE7a"
  },
  tw: {
    name: "Twi",
    native: "Twi"
  },
  ty: {
    name: "Tahitian",
    native: "Reo M\u0101`ohi"
  },
  ug: {
    name: "Uyghur",
    native: "Uy\u01A3urq\u0259 / \u0626\u06C7\u064A\u063A\u06C7\u0631\u0686\u06D5"
  },
  uk: {
    name: "Ukrainian",
    native: "\u0423\u043A\u0440\u0430\u0457\u043D\u0441\u044C\u043A\u0430"
  },
  ur: {
    name: "Urdu",
    native: "\u0627\u0631\u062F\u0648",
    rtl: 1
  },
  uz: {
    name: "Uzbek",
    native: "O'zbekcha"
  },
  ve: {
    name: "Venda",
    native: "Tshiven\u1E13a"
  },
  vi: {
    name: "Vietnamese",
    native: "Ti\u1EBFng Vi\u1EC7t"
  },
  vo: {
    name: "Volap\xFCk",
    native: "Volap\xFCk"
  },
  wa: {
    name: "Walloon",
    native: "Walon"
  },
  wo: {
    name: "Wolof",
    native: "Wollof"
  },
  xh: {
    name: "Xhosa",
    native: "isiXhosa"
  },
  yi: {
    name: "Yiddish",
    native: "\u05D9\u05D9\u05B4\u05D3\u05D9\u05E9",
    rtl: 1
  },
  yo: {
    name: "Yoruba",
    native: "Yor\xF9b\xE1"
  },
  za: {
    name: "Zhuang",
    native: "Cuengh / T\xF4\xF4 / \u58EE\u8BED"
  },
  zh: {
    name: "Chinese",
    native: "\u4E2D\u6587"
  },
  zu: {
    name: "Zulu",
    native: "isiZulu"
  }
};
var r = {
  AD: "AND",
  AE: "ARE",
  AF: "AFG",
  AG: "ATG",
  AI: "AIA",
  AL: "ALB",
  AM: "ARM",
  AO: "AGO",
  AQ: "ATA",
  AR: "ARG",
  AS: "ASM",
  AT: "AUT",
  AU: "AUS",
  AW: "ABW",
  AX: "ALA",
  AZ: "AZE",
  BA: "BIH",
  BB: "BRB",
  BD: "BGD",
  BE: "BEL",
  BF: "BFA",
  BG: "BGR",
  BH: "BHR",
  BI: "BDI",
  BJ: "BEN",
  BL: "BLM",
  BM: "BMU",
  BN: "BRN",
  BO: "BOL",
  BQ: "BES",
  BR: "BRA",
  BS: "BHS",
  BT: "BTN",
  BV: "BVT",
  BW: "BWA",
  BY: "BLR",
  BZ: "BLZ",
  CA: "CAN",
  CC: "CCK",
  CD: "COD",
  CF: "CAF",
  CG: "COG",
  CH: "CHE",
  CI: "CIV",
  CK: "COK",
  CL: "CHL",
  CM: "CMR",
  CN: "CHN",
  CO: "COL",
  CR: "CRI",
  CU: "CUB",
  CV: "CPV",
  CW: "CUW",
  CX: "CXR",
  CY: "CYP",
  CZ: "CZE",
  DE: "DEU",
  DJ: "DJI",
  DK: "DNK",
  DM: "DMA",
  DO: "DOM",
  DZ: "DZA",
  EC: "ECU",
  EE: "EST",
  EG: "EGY",
  EH: "ESH",
  ER: "ERI",
  ES: "ESP",
  ET: "ETH",
  FI: "FIN",
  FJ: "FJI",
  FK: "FLK",
  FM: "FSM",
  FO: "FRO",
  FR: "FRA",
  GA: "GAB",
  GB: "GBR",
  GD: "GRD",
  GE: "GEO",
  GF: "GUF",
  GG: "GGY",
  GH: "GHA",
  GI: "GIB",
  GL: "GRL",
  GM: "GMB",
  GN: "GIN",
  GP: "GLP",
  GQ: "GNQ",
  GR: "GRC",
  GS: "SGS",
  GT: "GTM",
  GU: "GUM",
  GW: "GNB",
  GY: "GUY",
  HK: "HKG",
  HM: "HMD",
  HN: "HND",
  HR: "HRV",
  HT: "HTI",
  HU: "HUN",
  ID: "IDN",
  IE: "IRL",
  IL: "ISR",
  IM: "IMN",
  IN: "IND",
  IO: "IOT",
  IQ: "IRQ",
  IR: "IRN",
  IS: "ISL",
  IT: "ITA",
  JE: "JEY",
  JM: "JAM",
  JO: "JOR",
  JP: "JPN",
  KE: "KEN",
  KG: "KGZ",
  KH: "KHM",
  KI: "KIR",
  KM: "COM",
  KN: "KNA",
  KP: "PRK",
  KR: "KOR",
  KW: "KWT",
  KY: "CYM",
  KZ: "KAZ",
  LA: "LAO",
  LB: "LBN",
  LC: "LCA",
  LI: "LIE",
  LK: "LKA",
  LR: "LBR",
  LS: "LSO",
  LT: "LTU",
  LU: "LUX",
  LV: "LVA",
  LY: "LBY",
  MA: "MAR",
  MC: "MCO",
  MD: "MDA",
  ME: "MNE",
  MF: "MAF",
  MG: "MDG",
  MH: "MHL",
  MK: "MKD",
  ML: "MLI",
  MM: "MMR",
  MN: "MNG",
  MO: "MAC",
  MP: "MNP",
  MQ: "MTQ",
  MR: "MRT",
  MS: "MSR",
  MT: "MLT",
  MU: "MUS",
  MV: "MDV",
  MW: "MWI",
  MX: "MEX",
  MY: "MYS",
  MZ: "MOZ",
  NA: "NAM",
  NC: "NCL",
  NE: "NER",
  NF: "NFK",
  NG: "NGA",
  NI: "NIC",
  NL: "NLD",
  NO: "NOR",
  NP: "NPL",
  NR: "NRU",
  NU: "NIU",
  NZ: "NZL",
  OM: "OMN",
  PA: "PAN",
  PE: "PER",
  PF: "PYF",
  PG: "PNG",
  PH: "PHL",
  PK: "PAK",
  PL: "POL",
  PM: "SPM",
  PN: "PCN",
  PR: "PRI",
  PS: "PSE",
  PT: "PRT",
  PW: "PLW",
  PY: "PRY",
  QA: "QAT",
  RE: "REU",
  RO: "ROU",
  RS: "SRB",
  RU: "RUS",
  RW: "RWA",
  SA: "SAU",
  SB: "SLB",
  SC: "SYC",
  SD: "SDN",
  SE: "SWE",
  SG: "SGP",
  SH: "SHN",
  SI: "SVN",
  SJ: "SJM",
  SK: "SVK",
  SL: "SLE",
  SM: "SMR",
  SN: "SEN",
  SO: "SOM",
  SR: "SUR",
  SS: "SSD",
  ST: "STP",
  SV: "SLV",
  SX: "SXM",
  SY: "SYR",
  SZ: "SWZ",
  TC: "TCA",
  TD: "TCD",
  TF: "ATF",
  TG: "TGO",
  TH: "THA",
  TJ: "TJK",
  TK: "TKL",
  TL: "TLS",
  TM: "TKM",
  TN: "TUN",
  TO: "TON",
  TR: "TUR",
  TT: "TTO",
  TV: "TUV",
  TW: "TWN",
  TZ: "TZA",
  UA: "UKR",
  UG: "UGA",
  UM: "UMI",
  US: "USA",
  UY: "URY",
  UZ: "UZB",
  VA: "VAT",
  VC: "VCT",
  VE: "VEN",
  VG: "VGB",
  VI: "VIR",
  VN: "VNM",
  VU: "VUT",
  WF: "WLF",
  WS: "WSM",
  XK: "XKX",
  YE: "YEM",
  YT: "MYT",
  ZA: "ZAF",
  ZM: "ZMB",
  ZW: "ZWE"
};
var c = n => ({
    ...a[n],
    iso2: n,
    iso3: r[n]
  }),
  t = () => Object.keys(a).map(n => c(n));
var g = t(),
  m = n => {
    let e = `${n}`.trim().replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d"),
      i = new RegExp("^" + e + "$", "i");
    return g.find(({
      name: o,
      native: l
    }) => i.test(o) || i.test(l))?.iso2 || !1;
  };
var p = 127397,
  v = /^[A-Z]{2}$/,
  h = n => v.test(n) ? String.fromCodePoint(...n.split("").map(e => p + e.toUpperCase().charCodeAt(0))) : "";
export { u as continents, a as countries, m as getCountryCode, c as getCountryData, t as getCountryDataList, h as getEmojiFlag, s as languages };