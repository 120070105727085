<div class="mt-12 flex flex-col items-center container-background-color {{classes}}">
  <div class="absolute" style="top: 10px; right:0px"> <!-- Virker sammen med position absolute på app-root -->
    <app-language-selector *ngIf="!isLoading"></app-language-selector>
  </div>
  <div class="flex flex-col items-center w-full min-sm:mb-7 text-center" style="margin-top:70px; margin-bottom: 30px" [ngClass]="{ 'hidden': !showRestaurantNameAndAddress}">
    <h1 class="text-4xl md:text-4xl">{{restaurantName}}</h1>
    <p class="max-[320px]:text-xs" *ngIf="address && city && country">
      <a [href]="'https://www.google.com/maps/search/?api=1&query=' + restaurantName + ',' + city + ',' + country" target="_blank">
        {{address}}, {{city}}
      </a>
    </p>
  </div>

  <div *ngIf="isLoading" class="min-h-96 pt-28">
    <span class="loading loading-ring loading-lg"></span>
  </div>
  <div *ngIf="!isLoadingWidgetConfigurations && !isLoading" class="dynamic-border-radius dynamícWidth p-2" style="{{isPreviewMode ? 'margin-top: 20px!important' : ''}}">
    <!-- stepper navigator -->
    <app-stepper [currentStep]="currentStep"></app-stepper>

    <!-- stepper content -->
    <div class="p-4 flex justify-center">
      <app-select-guests
        *ngIf="currentStep === 1"
        [numberOfGuests]="bookingForm.value.numberOfGuests ?? 0"
        [contactEmail]="contactEmail"
        [contactPhone]="contactPhone"
        [minGuests]="minNumberOfGuestsAcceptedOnline"
        [maxGuests]="maxNumberOfGuestsAcceptedOnline"
        [restaurantName]="restaurantName"
        (selectGuests)="selectGuests($event)"
      ></app-select-guests>
      <app-select-date
        *ngIf="currentStep === 2"
        (onSelectDate)="onSelectDate($event)"
        [selectedDate]="bookingForm.value.date ?? undefined"
        [numberOfGuests]="bookingForm.value.numberOfGuests ?? 0"
        [generalOpeningHours]="generalOpeningHours"
        [maxBookingDays]="maxBookingDays"
        (onBack)="onBack()"
      >
      </app-select-date>
      <app-select-time
        *ngIf="currentStep === 3"
        (onSelectTime)="onSelectTime($event)"
        [selectedTime]="bookingForm.value.time ?? undefined"
        [selectedDate]="bookingForm.value.date ?? undefined"
        [numberOfGuests]="bookingForm.value.numberOfGuests ?? 0"
        [contactEmail]="contactEmail"
        [contactPhone]="contactPhone"
        [areaId]="areaId"
        [restaurantId]="restaurantId"
        [tenantId]="tenantId"
        [areas]="areas"
        [servingAreaChoice]="servingAreaChoice"
        (onBack)="onBack()"
      >
        >
      </app-select-time>
      <app-contact-info
        *ngIf="currentStep === 4"
        [isLoading]="isLoading"
        (onSubmitContactInfo)="onSubmitContactInfo($event)"
        [selectedDate]="bookingForm.value.date ?? undefined"
        [selectedTime]="bookingForm.value.time ?? undefined"
        [numberOfGuests]="bookingForm.value.numberOfGuests ?? 0"
        [country]="country"
        (onBack)="onBack()"
      >
      </app-contact-info>
      <app-confirmation
        *ngIf="currentStep === 5 && !isLoading"
        [bookingRequest]="bookingRequest"
        [restaurantName]="restaurantName"
        [modifyId]="modifyId"
        [contactEmail]="contactEmail"
        [contactPhone]="contactPhone"
      ></app-confirmation>
      <app-booking-failed
        *ngIf="currentStep === 500"
        (onBack)="onBack()"
      ></app-booking-failed>

    </div>
  </div>
  <div class="mt-7 mb-7 text-center w-75" style="color: #adadad">
    <p>{{'common.deliveredByResmium' | translate }} <a href="https://resmium.com">Resmium</a></p>
  </div>




</div>
