<div class="flex flex-col items-center">

    <div class="summary-wrapper">
      <h2 class="stepper-title" translate> booking_failed.title </h2>
      <p translate> booking_failed.description </p>
    </div>
  
    <button (click)="back()" type="button" class="btn btn-outline">
        <span translate>common.back</span>
    </button>
</div>  