var langRegionMap = {
  en: 'US',
  hi: 'IN',
  deva: 'IN',
  te: 'IN',
  mr: 'IN',
  ta: 'IN',
  gu: 'IN',
  kn: 'IN',
  or: 'IN',
  ml: 'IN',
  pa: 'IN',
  bho: 'IN',
  awa: 'IN',
  as: 'IN',
  mwr: 'IN',
  mai: 'IN',
  mag: 'IN',
  bgc: 'IN',
  hne: 'IN',
  dcc: 'IN',
  bn: 'BD',
  beng: 'BD',
  rkt: 'BD',
  dz: 'BT',
  tibt: 'BT',
  tn: 'BW',
  am: 'ET',
  ethi: 'ET',
  om: 'ET',
  quc: 'GT',
  id: 'ID',
  jv: 'ID',
  su: 'ID',
  mad: 'ID',
  ms_arab: 'ID',
  he: 'IL',
  hebr: 'IL',
  jam: 'JM',
  ja: 'JP',
  jpan: 'JP',
  km: 'KH',
  khmr: 'KH',
  ko: 'KR',
  kore: 'KR',
  lo: 'LA',
  laoo: 'LA',
  mh: 'MH',
  my: 'MM',
  mymr: 'MM',
  mt: 'MT',
  ne: 'NP',
  fil: 'PH',
  ceb: 'PH',
  ilo: 'PH',
  ur: 'PK',
  pa_arab: 'PK',
  lah: 'PK',
  ps: 'PK',
  sd: 'PK',
  skr: 'PK',
  gn: 'PY',
  th: 'TH',
  thai: 'TH',
  tts: 'TH',
  zh_hant: 'TW',
  hant: 'TW',
  sm: 'WS',
  zu: 'ZA',
  sn: 'ZW',
  arq: 'DZ',
  ar: 'EG',
  arab: 'EG',
  arz: 'EG',
  fa: 'IR',
  az_arab: 'IR',
  dv: 'MV',
  thaa: 'MV'
};
export default langRegionMap;
