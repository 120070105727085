import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-select-guests',
  templateUrl: './select-guests.component.html',
  styleUrls: ['./select-guests.component.css']
})
export class SelectGuestsComponent implements OnInit {
  @Input() minGuests!: number;
  @Input() maxGuests!: number;
  @Input() numberOfGuests!: number;

  @Input() contactPhone?: string;
  @Input() contactEmail?: string;

  // From API
  @Input() restaurantName?: string;

  numberOfGuestsForm!: FormGroup;

  @Output() selectGuests: EventEmitter<number> = new EventEmitter<number>();

	constructor() {}

  ngOnInit(): void {
    this.numberOfGuestsForm = new FormGroup({
      numberOfGuests: new FormControl(0, [Validators.required, Validators.min(this.minGuests), Validators.max(this.maxGuests)]),
    });

    if (this.minGuests === undefined || this.maxGuests === undefined || this.numberOfGuests === undefined) {
      throw new Error('Missing required input values: minGuests, maxGuests, numberOfGuests, and isLoading');
    }
    if(this.numberOfGuests > 0){
      this.numberOfGuestsForm.get('numberOfGuests')?.setValue(this.numberOfGuests);
    }
  }

  submit(){
    this.numberOfGuestsForm.markAllAsTouched();

    if(this.numberOfGuestsForm.invalid){
      return;
    }

    const selectedNumberOfGuests = this.numberOfGuestsForm.get('numberOfGuests')?.value;
    if(selectedNumberOfGuests && selectedNumberOfGuests > 0){
      this.selectGuests.emit(selectedNumberOfGuests);
    }
  }
}
