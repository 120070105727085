var regionDayMap = {
  AG: 0,
  ATG: 0,
  28: 0,
  AS: 0,
  ASM: 0,
  16: 0,
  BD: 0,
  BGD: 0,
  50: 0,
  BR: 0,
  BRA: 0,
  76: 0,
  BS: 0,
  BHS: 0,
  44: 0,
  BT: 0,
  BTN: 0,
  64: 0,
  BW: 0,
  BWA: 0,
  72: 0,
  BZ: 0,
  BLZ: 0,
  84: 0,
  CA: 0,
  CAN: 0,
  124: 0,
  CO: 0,
  COL: 0,
  170: 0,
  DM: 0,
  DMA: 0,
  212: 0,
  DO: 0,
  DOM: 0,
  214: 0,
  ET: 0,
  ETH: 0,
  231: 0,
  GT: 0,
  GTM: 0,
  320: 0,
  GU: 0,
  GUM: 0,
  316: 0,
  HK: 0,
  HKG: 0,
  344: 0,
  HN: 0,
  HND: 0,
  340: 0,
  ID: 0,
  IDN: 0,
  360: 0,
  IL: 0,
  ISR: 0,
  376: 0,
  IN: 0,
  IND: 0,
  356: 0,
  JM: 0,
  JAM: 0,
  388: 0,
  JP: 0,
  JPN: 0,
  392: 0,
  KE: 0,
  KEN: 0,
  404: 0,
  KH: 0,
  KHM: 0,
  116: 0,
  KR: 0,
  KOR: 0,
  410: 0,
  LA: 0,
  LA0: 0,
  418: 0,
  MH: 0,
  MHL: 0,
  584: 0,
  MM: 0,
  MMR: 0,
  104: 0,
  MO: 0,
  MAC: 0,
  446: 0,
  MT: 0,
  MLT: 0,
  470: 0,
  MX: 0,
  MEX: 0,
  484: 0,
  MZ: 0,
  MOZ: 0,
  508: 0,
  NI: 0,
  NIC: 0,
  558: 0,
  NP: 0,
  NPL: 0,
  524: 0,
  PA: 0,
  PAN: 0,
  591: 0,
  PE: 0,
  PER: 0,
  604: 0,
  PH: 0,
  PHL: 0,
  608: 0,
  PK: 0,
  PAK: 0,
  586: 0,
  PR: 0,
  PRI: 0,
  630: 0,
  PT: 0,
  PRT: 0,
  620: 0,
  PY: 0,
  PRY: 0,
  600: 0,
  SA: 0,
  SAU: 0,
  682: 0,
  SG: 0,
  SGP: 0,
  702: 0,
  SV: 0,
  SLV: 0,
  222: 0,
  TH: 0,
  THA: 0,
  764: 0,
  TT: 0,
  TTO: 0,
  780: 0,
  TW: 0,
  TWN: 0,
  158: 0,
  UM: 0,
  UMI: 0,
  581: 0,
  US: 0,
  USA: 0,
  840: 0,
  VE: 0,
  VEN: 0,
  862: 0,
  VI: 0,
  VIR: 0,
  850: 0,
  WS: 0,
  WSM: 0,
  882: 0,
  YE: 0,
  YEM: 0,
  887: 0,
  ZA: 0,
  ZAF: 0,
  710: 0,
  ZW: 0,
  ZWE: 0,
  716: 0,
  AE: 6,
  ARE: 6,
  784: 6,
  AF: 6,
  AFG: 6,
  4: 6,
  BH: 6,
  BHR: 6,
  48: 6,
  DJ: 6,
  DJI: 6,
  262: 6,
  DZ: 6,
  DZA: 6,
  12: 6,
  EG: 6,
  EGY: 6,
  818: 6,
  IQ: 6,
  IRQ: 6,
  368: 6,
  IR: 6,
  IRN: 6,
  364: 6,
  JO: 6,
  JOR: 6,
  400: 6,
  KW: 6,
  KWT: 6,
  414: 6,
  LY: 6,
  LBY: 6,
  434: 6,
  OM: 6,
  OMN: 6,
  512: 6,
  QA: 6,
  QAT: 6,
  634: 6,
  SD: 6,
  SDN: 6,
  729: 6,
  SY: 6,
  SYR: 6,
  760: 6,
  MV: 5,
  MDV: 5,
  462: 5
};
export default regionDayMap;
