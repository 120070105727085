import { Pipe, PipeTransform } from '@angular/core';
import {DateTime, Settings} from "luxon";

@Pipe({
  name: 'localizedDateString'
})
export class LocalizedDateStringPipe implements PipeTransform {

  transform(dateTime?: DateTime, formatOptions?: Intl.DateTimeFormatOptions): string {
    if (!dateTime) {
      return '';
    }

    let format = DateTime.DATE_FULL;
    if (formatOptions) {
     format = formatOptions;
    }

    return dateTime.toLocaleString(format, { locale: Settings.defaultLocale });
  }

}
