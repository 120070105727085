import { Component, OnInit, OnDestroy, ViewEncapsulation, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {Subscription, merge, of} from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import {DateTime} from "luxon";
import { environment } from '@env/environment';
import { I18nService } from '@app/i18n';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactInfoForm } from '@app/components/contact-info/contact-info.component';
import { SupabaseService } from '@app/services/supabase.service';
import { StateService } from '@app/services/state.service';
import { GeneralOpeningHours } from '@app/models/general-opening-hours.model';
import { BookingRequest } from '@app/models/booking-request.model';
import { WidgetConfigurations } from '@app/models/widget-configurations.model';
import { hexToRgb } from '@app/utils/hex-to-rgb';
import * as Sentry from "@sentry/angular";
import { Areas } from '@app/models/areas.model';

@UntilDestroy()
@Component({
  selector: 'app-seatly-booking-widget',
  templateUrl: './seatly-booking-widget.component.html',
  styleUrls: ['./seatly-booking-widget.component.css', '../../../styles.css'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SeatlyBookingWidgetComponent implements OnInit, OnDestroy {
  // Web component inputs
  @Input() tenantId?: string;
  @Input() restaurantId?: string;
  isLoading: boolean = true;
  isLoadingWidgetConfigurations: boolean = true;

  // General settings for the booking widget
  maxBookingDays: number = 365; // Fallback to 365 if not set in API
  maxNumberOfGuestsAcceptedOnline: number = 7; // fallback to 7 if not set in API
  minNumberOfGuestsAcceptedOnline: number = 1 // Fallback to 1 if not set in API
  servingAreaChoice: boolean = false // Fallback to False if not set in API
  totalSteps: number = 4;
  currentStep: number = 1;

  // Final form that will complete the booking.
  bookingForm = new FormGroup({
    numberOfGuests: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(this.maxNumberOfGuestsAcceptedOnline)]),
    date: new FormControl<DateTime>(DateTime.now(), [Validators.required]),
    time: new FormControl(0, [Validators.required]),
    contactPerson: new FormGroup({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      comment: new FormControl(''),
    }),
  });
  selectedDate: DateTime | null = null;
  bookingRequest?: BookingRequest;

  // API data needed to generate the form for the booking widget.
  restaurantName?: string;
  address?: string;
  city?: string;
  country?: string;
  contactEmail?: string;
  contactPhone?: string;
  generalOpeningHours: GeneralOpeningHours[] = [];
  areaId?: number;
  areas?: Areas[];
  missingRequiredAttributes: boolean = false;

  // Subscriptions
  restaurantInfoSubscription?: Subscription;
  isPreviewMode: boolean = false;
  private previewConfiguration: WidgetConfigurations = {
    primaryColor: '#efc805',
    primaryColorContrast: '#d90c0c',
    hasFrame: true,
    showRestaurantNameAndAddress: true,
    font: 'Arial',
    fontColor: '#02e802',
    backgroundColor: '#e8e8e8',
    containerColor: '#fff',
    restaurantId: '0',
    id: '0',
    classes: '',
    borderRadius: 25,

    maxBookingDays: 365,
    maxNumberOfGuestsAcceptedOnline: 7,
    minNumberOfGuestsAcceptedOnline: 1,
    servingAreaChoice: true,
  };
  classes: string = '';
  modifyId: string | undefined;
  showRestaurantNameAndAddress: boolean = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private supabaseService: SupabaseService,
    private stateService: StateService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.i18nSetup().then(() => {
      this.getParams();
      this.getWidgetConfigurations();
      this.getRestaurantData();
      this.handleBrowserNavigation()
    });
  }

  private handleBrowserNavigation() {

    // Load data from Query Params - Only called on Init()
    const params = this.route.snapshot.queryParams;
    const { guestCount, date, timeOfDay, bookingConfirmed, bookingId, bookingUuid, ...rest } = params;

    this.bookingForm.get('numberOfGuests')?.setValue(guestCount ? Number(guestCount) : null);
    this.selectedDate = date ? DateTime.fromISO(date) : null
    this.bookingForm.get('date')?.setValue(this.selectedDate);
    this.bookingForm.get('time')?.setValue(timeOfDay ? timeOfDay : null);

    // Side Effect when updating Query Params
    this.route.queryParams.subscribe((params) => {

      const { guestCount, date, timeOfDay, bookingConfirmed, ...rest } = params;

      const isStep1 = !guestCount && !date && !timeOfDay && !bookingConfirmed // Select-Guests
      const isStep2 = guestCount && !date && !timeOfDay && !bookingConfirmed  // Select-Date
      const isStep3 = guestCount && date && !timeOfDay && !bookingConfirmed  // Select-Time
      const isStep4 = guestCount && date && timeOfDay && !bookingConfirmed  // Contact-Info
      const isStep5 = !guestCount && !date && !timeOfDay && bookingConfirmed // Booking-Confirmation

      if (isStep1) {
        this.currentStep = 1;
      };
      if (isStep2) {
        this.currentStep = 2;
      };
      if (isStep3) {
        this.currentStep = 3;
      };
      if (isStep4) {
        this.currentStep = 4;
      };
      if (isStep5) {
        this.currentStep = 5;
      };

    })

    // Navigate to start if reload on booking confirmation page - Only called on Init()
    if (this.currentStep === 5) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: rest,
        replaceUrl: true
      });
      this.currentStep = 1;
    }
  }

  private updateRouteParams() {

    let params = this.route.snapshot.queryParams;

      const { guestCount, date, timeOfDay, bookingConfirmed, ...rest } = params;

      if (this.currentStep === 1) {
        params = rest
      }

      if (this.currentStep === 2) {
        params = rest

        const selectedNumberOfGuests = this.bookingForm.get('numberOfGuests')?.value;
        if (selectedNumberOfGuests !== null && selectedNumberOfGuests !== undefined) {
          params['guestCount'] = selectedNumberOfGuests;
        }
      }

      if (this.currentStep === 3) {
        params = {...rest, guestCount};

        const selectedDate = this.bookingForm.get('date')?.value?.toISODate();
        if (selectedDate) {
          params['date'] = selectedDate;
        }
      }

      if (this.currentStep === 4) {
        params = {...rest, guestCount, date};
        const selectedTimeOfDay = this.bookingForm.get('time')?.value;
        if (selectedTimeOfDay !== null && selectedTimeOfDay !== undefined && selectedTimeOfDay !== 0) {
          params['timeOfDay'] = selectedTimeOfDay;
        }
      }

      if (this.currentStep === 5) {
        params = rest
        params['bookingConfirmed'] = true;
      }

      if (this.currentStep === 500) {
        params = rest
        params['bookingConfirmed'] = false;
      }

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: params,
        replaceUrl: false
      });

  }

  private getParams() {
    // If the tenantID and restaurantID is not set trough the web-component,
    // try to get it from the URL.
    if (!this.tenantId && !this.restaurantId) {
      this.route.queryParams.subscribe(params => {
        this.tenantId = params['tenantId'];
        this.restaurantId = params['restaurantId'];
        this.isPreviewMode = params['isPreviewMode'];
        if (this.isPreviewMode) {
          const event = new CustomEvent('hideFrameworkLoader');
          window.dispatchEvent(event);
          this.previewConfiguration = {
            primaryColor: decodeURIComponent(params['primaryColor']),
            primaryColorContrast: decodeURIComponent(params['primaryColorContrast']),
            hasFrame: params['hasFrame'] === 'true',
            showRestaurantNameAndAddress: params['showRestaurantNameAndAddress'] === 'true',
            font: params['font'],
            restaurantId: params['restaurantId'],
            classes: decodeURIComponent(params['classes']),
            fontColor: decodeURIComponent(params['fontColor']),
            borderRadius: +decodeURIComponent(params['borderRadius']),
            backgroundColor: decodeURIComponent(params['backgroundColor']),
            containerColor: decodeURIComponent(params['containerColor']),
            id: '',

            maxBookingDays: this.maxBookingDays,
            minNumberOfGuestsAcceptedOnline: this.minNumberOfGuestsAcceptedOnline,
            maxNumberOfGuestsAcceptedOnline: this.maxBookingDays,
            servingAreaChoice: this.servingAreaChoice,
          };
        }
        console.log('Params:', this.tenantId, this.restaurantId, this.isPreviewMode, this.previewConfiguration)

        Sentry.setUser({
          'Tenant Id': this.tenantId,
          'Restaurant Id': this.restaurantId,
          'Preview mode': this.isPreviewMode,
        })
      });
    }
  }

  private getWidgetConfigurations() {
    if (this.tenantId) {

      if (this.isPreviewMode) {
        this.setStyle(this.previewConfiguration);
        this.isLoadingWidgetConfigurations = false;
      } else {
        this.supabaseService.getWidgetConfigurations(this.tenantId!, this.restaurantId!).subscribe({
          next: response => {
            if(response.error) throw response.error;
            const data = response.data;
            if (!data) {
              return;
            }

            this.setWidgetSettings(data);
            this.setStyle(data);

          },
          error: error => {
            console.error(error);
          },
          complete: () => {
            this.isLoadingWidgetConfigurations = false;

            // se main.ts for lytterlogikken. Denne sørger for at loaderen skjules når widget MED data er ferdig lastet. Kjedelig å vise siden og så venter man på data fortsatt. Ser rart ut..
            const event = new CustomEvent('hideFrameworkLoader');
            window.dispatchEvent(event);
          }
        });
      }
    }
  }

  private setStyle(widgetConfigurations: WidgetConfigurations){
    if(widgetConfigurations.primaryColor){
      document.documentElement.style.setProperty('--primary-color', widgetConfigurations.primaryColor);
      // document.documentElement.style.setProperty('--p', widgetConfigurations.primaryColor);
      const primaryColorLight = hexToRgb(widgetConfigurations.primaryColor);
      // create a lighter color if primar color is set with opacity
      if(primaryColorLight){
        document.documentElement.style.setProperty('--primary-color-lighter', `rgba(${primaryColorLight.r}, ${primaryColorLight.g}, ${primaryColorLight.b}, 0.5)`);
      }
    }
    if(widgetConfigurations.primaryColorContrast){
      document.documentElement.style.setProperty('--primary-color-contrast', widgetConfigurations.primaryColorContrast);
    }
    if(widgetConfigurations.hasFrame){
      this.classes += ' shadow-xl';
    }

    this.showRestaurantNameAndAddress = new Boolean(widgetConfigurations.showRestaurantNameAndAddress).valueOf();


    if(widgetConfigurations.font){
      document.documentElement.style.fontFamily = widgetConfigurations.font;
      document.body.style.fontFamily = widgetConfigurations.font;
    }

    if(widgetConfigurations.classes){
      this.classes += ' ' + widgetConfigurations.classes;
    }

    if(widgetConfigurations.fontColor){
      document.body.style.color = widgetConfigurations.fontColor;
    }

    if(widgetConfigurations.borderRadius){
      document.documentElement.style.setProperty('--border-radius', widgetConfigurations.borderRadius + 'px');
    }

    if(widgetConfigurations.containerColor){
      document.documentElement.style.setProperty('--container-color', widgetConfigurations.containerColor);
    }

    if(widgetConfigurations.backgroundColor){
      document.body.style.backgroundColor = widgetConfigurations.backgroundColor;
    }

  }

  setWidgetSettings(data: WidgetConfigurations) {
    this.maxBookingDays = data.maxBookingDays ?? this.maxBookingDays;
    this.maxNumberOfGuestsAcceptedOnline = data.maxNumberOfGuestsAcceptedOnline ?? this.maxNumberOfGuestsAcceptedOnline;
    this.minNumberOfGuestsAcceptedOnline = data.minNumberOfGuestsAcceptedOnline ?? this.minNumberOfGuestsAcceptedOnline;
    this.servingAreaChoice = data.servingAreaChoice ?? this.servingAreaChoice;
  }

  private getRestaurantData() {
    // Still no tenantID or restaurantId. Throw error.
    if (!this.tenantId || !this.restaurantId) {
      console.error(
        'No tenantID or restaurantID provided. Please provide these as attributes to the web-component or as query parameters in the URL.',
      );
      this.router.navigate(['/attribute-error']);
      return;
    }

    // Get restaurant info from API
    this.restaurantInfoSubscription = this.supabaseService.getRestaurantInfo(this.tenantId, this.restaurantId, this.i18nService.currentLanguage).subscribe(
      restaurantInfo => {
        if (restaurantInfo.status === 200) {
          if (restaurantInfo.data.length === 0) {
            return;
          }
          // Can prob to all this into one model. Not sure why i mapped it into
          // one and one value. But can fix one day....
          const data = restaurantInfo.data[0];
          this.stateService.setRestaurantInfo(data);
          this.restaurantName = data.name;
          this.address = data.address;
          this.city = data.city;
          this.country = data.country;
          this.contactEmail = data.contactEmail;
          this.contactPhone = data.contactPhone;

          data.general_opening_hours.forEach((oh: any) => {
            oh.activeFromDate = DateTime.fromISO(oh.activeFromDate).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toJSDate();
            oh.activeToDate = DateTime.fromISO(oh.activeToDate).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toJSDate();
          });

          this.generalOpeningHours = data.general_opening_hours;

          this.bookingForm.get('numberOfGuests')?.setValidators([Validators.required, Validators.min(this.minNumberOfGuestsAcceptedOnline), Validators.max(this.maxNumberOfGuestsAcceptedOnline)]);

/*           if (data.areas && data.areas.length > 0) { // sjekker om areas kommer ut for hvis det er en ny restaurant så er det ikke sikker denne er laget enda
            this.areaId = data.areas[0].id;
          } */
         this.areaId = undefined;
          this.areas = data.areas.map((area: any) => {
            return {
              id: area.id,
              name: of(area.name), // dette er for at jeg skal kunne bruke async pipe i templaten slik at jeg igjen kan bruke ngx translate sin stream for å hente ut teksten for "Show all areas"
              availableOnline: area.availableOnline,
            };
          });
          this.titleService.setTitle(this.translateService.instant('common.title', { restaurantName: this.restaurantName }));
          this.isLoading = false;
        }
      },
      () => {
        this.isLoading = false;
      },
    );
  }

  private async i18nSetup() {
    // Setup translations
    await this.i18nService.init(environment.defaultLanguage);
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd).pipe(
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      switchMap(route => route.data),
      untilDestroyed(this),
    );
  }

  selectGuests(numberOfGuestsSelecetd: number) {
    console.info('Number of guests selected:', numberOfGuestsSelecetd);
    this.bookingForm.get('numberOfGuests')?.setValue(numberOfGuestsSelecetd);
    this.currentStep = 2;
    this.updateRouteParams();
  }

  onSelectDate(date: DateTime) {
    console.info('Date selected:', date);
    this.selectedDate = date;
    this.bookingForm.get('date')?.setValue(date);
    this.currentStep = 3;
    this.updateRouteParams();
  }

  onSelectTime(event: { time: number, area: number | undefined }) {
    console.info('Time selected:', event.time);
    this.bookingForm.get('time')?.setValue(event.time);
    this.areaId = event.area;
    this.currentStep = 4;
    this.updateRouteParams();
  }

  onSubmitContactInfo(contactInfo: ContactInfoForm) {

    this.isLoading = true;
    this.bookingForm.get('contactPerson')?.setValue(contactInfo);

    // kommer aldri inn her..
    if (this.bookingForm.valid && this.tenantId && this.restaurantId && this.bookingForm.value.date) {
      console.log("valid");
      const bookingRequest: BookingRequest = {
        waitingList: false,
        waitingListFlexibility: 0,
        tenantId: this.tenantId,
        restaurantId: Number.parseInt(this.restaurantId),
        areaId: this.areaId || null,
        paxCount: this.bookingForm.value.numberOfGuests ?? 0,
        date: this.bookingForm.value.date.toFormat('yyyy-MM-dd'),
        timeslot: this.bookingForm.value.time!,
        source: 'Online Registration',
        customerNotes: this.bookingForm.value.contactPerson?.comment ?? '',
        customer: {
          firstname: this.bookingForm.value.contactPerson?.firstname ?? '',
          lastname: this.bookingForm.value.contactPerson?.lastname ?? '',
          phoneNumber: this.bookingForm.value.contactPerson?.phone ?? '',
          email: this.bookingForm.value.contactPerson?.email ?? '',
          gdprConsent: DateTime.now().plus({year: 2}).toFormat('yyyy-MM-dd'),
          desiredCommunicationLanguage: navigator.language.substring(0, 2),
        },
      };

      this.supabaseService.createBooking(bookingRequest).subscribe(
        response => {
          if (response.bookingStatus === 'confirmed') {
            this.bookingRequest = bookingRequest;
            this.modifyId = response.modifyId;
            this.currentStep = 5;
            this.updateRouteParams();
            this.isLoading = false;
          } else {
            this.currentStep = 500; // error code
            this.isLoading = false;
          }
        },
        (e) => {
          this.isLoading = false;
          console.error('Error', e);
        },
      );
    }
  }

  onBack() {
    if (this.currentStep === 500) {
      this.currentStep = 3;
    } else {
      this.currentStep -= 1;
    }
    this.updateRouteParams();
  }

  ngOnDestroy() {
    this.i18nService.destroy();
    this.restaurantInfoSubscription?.unsubscribe();
  }
}
