import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-booking-failed',
  templateUrl: './booking-failed.component.html',
  styleUrls: ['./booking-failed.component.css'],
})
export class BookingFailedComponent {  
  @Output() onBack = new EventEmitter<boolean>();
  back() {
    this.onBack.emit(true);
  }
}
