<form [formGroup]="phoneNumberForm" class="w-full mx-auto">
  <div class="relative flex items-center">
    <button
      (click)="toggleDropdown()"
      class="gap-1 flex items-center p-2 text-sm font-medium text-gray-900 border border-gray-300 rounded-l-lg "
      type="button"
      style="height: 46px;"
    >
      <div class="w-4">
        <img
          *ngIf="phoneNumberForm.value.country?.flag"
          class="w-full h-full"
          [src]="phoneNumberForm.value.country?.flag"
          alt="Country Flag"
        />
      </div>
      <!-- <p>{{phoneNumberForm.value.country?.callingCode}}</p> -->
      <svg
        class="w-2 h-2 ml-2.5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 10 6"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 4 4 4-4"
        />
      </svg>
    </button>

    <div
      *ngIf="isVisible"
      class="absolute top-full left-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-52 overflow-auto max-h-52 mt-1"
    >
      <ul class="py-2 text-sm text-gray-700">
        <li *ngFor="let country of countries">
          <button
            (click)="selectCountry(country)"
            type="button"
            class="w-full px-4 py-2 text-left hover:bg-gray-100"
          >
            {{ country.label }} ({{ country.callingCode }})
          </button>
        </li>
      </ul>
    </div>

    <div class="relative w-full">
      <label for="phone-input" class="sr-only">Phone number:</label>
      <input
        formControlName="phoneNumber"
        id="phone-input"
        class=" w-full p-3 text-sm text-gray-900 border border-gray-300 rounded-r-lg focus:outline-none"
        placeholder="55532368"
        type="number"
        pattern="[0-9]*"
        inputmode="numeric"
        required
      />
    </div>
  </div>
</form>
