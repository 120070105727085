/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular";

import { AppModule } from './app/app.module';
import {enableProdMode} from "@angular/core";

Sentry.init({
  dsn: "https://2e045e98b5bb4fc725a7573a81febd95@o4507826732466176.ingest.de.sentry.io/4507826857377872",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", 'book.resmium.com', 'staging-book.resmium.com'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: window.location.host === 'book.resmium.com' ? 'production' : window.location.host === 'staging-book.resmium.com' ? 'staging' : 'development',
});

if (window.location.host === 'book.resmium.com') {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

function hideLoader(): void {
  const loader = document.getElementById('loader');
  if (loader) {
    loader.style.display = 'none';
  }

  const appRoot = document.getElementById('app-root');
  if (appRoot) {
    appRoot.style.display = 'block';
  }

}

window.addEventListener('hideFrameworkLoader', hideLoader);
