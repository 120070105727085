import { Component, OnInit, OnDestroy, ViewEncapsulation, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, firstValueFrom, merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { I18nService } from '@app/i18n';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SupabaseService } from '@app/services/supabase.service';
import { StateService } from '@app/services/state.service';
import { DateTime } from 'luxon';
import { convertMinutesSinceMidnightToHumanReadableTime } from '@app/utils/time';
import { hexToRgb } from '@app/utils/hex-to-rgb';
import { WidgetConfigurations } from '@app/models/widget-configurations.model';
import * as Sentry from "@sentry/angular";


@UntilDestroy()
@Component({
  selector: 'booking-review-page',
  templateUrl: './booking-review.component.html',
  styleUrls: ['./booking-review.component.css', '../../../styles.css'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class BookingReviewComponent implements OnInit, OnDestroy {

  @Input() bookingUuid?: string;

  tenantId?: string;
  restaurantId?: string;

  isLoading: boolean = true;
  isRestaurantFound: boolean = false;
  isBookingFound: boolean = false;
  isConfirmationModalVisible = false;
  isErrorMessageDisplayed = false;

  // Restaurant Details
  restaurantName?: string;
  address?: string;
  city?: string;
  country?: string;
  contactEmail?: string;
  contactPhone?: string;

  // Booking Details
  firstName?: string;
  guests?: number;
  date?: string;
  timeOfDay?: string;
  status?: string;

  // Widget Configuration
  classes: string = '';
  isPreviewMode: boolean = false;
  private previewConfiguration: WidgetConfigurations = {
    primaryColor: '#efc805',
    primaryColorContrast: '#d90c0c',
    hasFrame: true,
    showRestaurantNameAndAddress: true,
    font: 'Arial',
    fontColor: '#02e802',
    backgroundColor: '#e8e8e8',
    containerColor: '#fff',
    restaurantId: '0',
    id: '0',
    classes: '',
    borderRadius: 25,

    maxBookingDays: 365,
    maxNumberOfGuestsAcceptedOnline: 7,
    minNumberOfGuestsAcceptedOnline: 1,
    servingAreaChoice: true,
  };
  isWaitingList: boolean = false;
  waitingListFlexibility: number | null = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private supabaseService: SupabaseService,
    private stateService: StateService,
    private route: ActivatedRoute,
  ) {};

  ngOnInit() {
    this.i18nSetup().then( async () => {
      this.getBookingParams();
      await this.getBookingData();
      await this.getRestaurantData();
    }).then(() => {
      this.getPreviewModeParams();
      this.getWidgetConfigurations();
      Sentry.setUser({
        'Tenant Id': this.tenantId,
        'Restaurant Id': this.restaurantId,
        'Preview mode': this.isPreviewMode,
      })
    })
  };

  private getBookingParams() {
    if (!this.bookingUuid) {

      this.route.paramMap.subscribe(params => {

        this.bookingUuid = params.get('bookingUuid') || undefined;

        if (!this.bookingUuid) {
          console.error(
            'No bookingUuid provided.',
          );
          this.router.navigate(['/attribute-error']);
        }
      });

      this.route.queryParams.subscribe(params => {
        this.isPreviewMode = params['isPreviewMode'];
      });
    }
  }

  private async i18nSetup() {
    // Setup translations
    await this.i18nService.init(environment.defaultLanguage);
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd).pipe(
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      switchMap(route => route.data),
      untilDestroyed(this),
    );
  };

  private async getRestaurantData() {

    if (!this.tenantId || !this.restaurantId) {
      console.error('Missing one or more attributes: TenantID, RestaurantId.');
      return;
    }

    try {
      const response = await firstValueFrom(this.supabaseService.getRestaurantInfo(this.tenantId, this.restaurantId, this.i18nService.currentLanguage));

      if (response.status === 200 && response.data.length > 0) {
        const data = response.data[0];

        this.stateService.setRestaurantInfo(data);
        this.restaurantName = data.name;
        this.address = data.address;
        this.city = data.city;
        this.country = data.country;
        this.contactEmail = data.contactEmail;
        this.contactPhone = data.contactPhone;

        this.titleService.setTitle(this.translateService.instant('common.title', { restaurantName: this.restaurantName }));
        this.isRestaurantFound = true;
      } else {
        throw new Error(`No restaurant data found or received unexpected status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error retrieving restaurant data:', error);
      this.isRestaurantFound = false;
    }
  };

  private async getBookingData() {

    if (!this.bookingUuid) {
      console.error('Missing attribute: BookingUUID. Please provide as attributes to the web component or as query parameters in the URL.');
      return;
    }

    try {
      const response = await this.supabaseService.getBooking(this.bookingUuid);

      if (response.status === 200 && response.body) {
        const data = response.body;

        this.tenantId = data.tenantId;
        this.restaurantId = data.restaurantId.toString();

        this.firstName = data.customer?.firstname;
        this.guests = data.guestCount;
        this.status = data.status;
        this.isWaitingList = data.status === 'Booking Requested';
        this.waitingListFlexibility = data.waitingListFlexibility;

        const dateTime = new Date(data.startTime);
        this.date = dateTime.toISOString().split('T')[0];
        const hoursSinceMidnight = this.getHoursSinceMidnight(data.startTime);
        const mintuesSinceMidnight = this.convertHoursToMinutesSinceMidnight(hoursSinceMidnight);
        this.timeOfDay = convertMinutesSinceMidnightToHumanReadableTime(mintuesSinceMidnight, this.i18nService.use12HourClock);

        this.isBookingFound = true;
      } else {
        throw new Error(`No booking data found or received unexpected status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error retrieving booking data:', error);
      this.isBookingFound = false;
    } finally {
        // Emit event to hide loader and update loading status
        const event = new CustomEvent('hideFrameworkLoader');
        window.dispatchEvent(event);
        this.isLoading = false;
    }
  };

  async deleteBookingHandler() {

    if (!this.tenantId || !this.restaurantId || !this.bookingUuid) {
      console.error('Missing one or more attributes: TenantId, RestaurantId, BookingUUID.');
      return;
    }

    try {

      const response = await this.supabaseService.deleteBooking(this.bookingUuid);

      if (response.status === 200) {
        this.closeConfirmationModal();
        this.router.navigate(['/cancellation-confirmed'], {
          queryParams: {
            tenantId: this.tenantId,
            restaurantId: this.restaurantId,
          }
        });
      } else {
        throw new Error(`Error deleting booking or received unexpected status: ${response.status}`);
      }
    } catch (error) {
      this.isErrorMessageDisplayed = true;
      console.error('Error deleting booking:', error);
    }
  };

  newBookingHandler() {
    this.router.navigate(['/booking'], {
      queryParams: { tenantId: this.tenantId, restaurantId: this.restaurantId }
    });
  };

  openConfirmationModal() {
    this.isConfirmationModalVisible = true;
  };

  closeConfirmationModal() {
    this.isConfirmationModalVisible = false;
    this.isErrorMessageDisplayed = false;
  };

  ngOnDestroy() {
    this.i18nService.destroy();
  };


  // Utilities
  getAsDatetime(date: any): DateTime | undefined {
    if (date){
      return DateTime.fromJSDate(new Date(date));
    }
    return
  };

  getAsHumanReadableTime(time: any): string | undefined {
    if(time){
      return convertMinutesSinceMidnightToHumanReadableTime(time, this.i18nService.use12HourClock);
    }
    return
  };

  getHoursSinceMidnight(timestamp: string): number {
    const date = new Date(timestamp);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    return hours + minutes / 60;
  };

  convertHoursToMinutesSinceMidnight(hoursSinceMidnight: number): number {
    return hoursSinceMidnight * 60;
  };


  // Widget Configurations
  private getPreviewModeParams() {

    if (this.isPreviewMode) {

      this.route.queryParams.subscribe(params => {

          this.previewConfiguration = {
            primaryColor: decodeURIComponent(params['primaryColor']),
            primaryColorContrast: decodeURIComponent(params['primaryColorContrast']),
            hasFrame: params['hasFrame'] === 'true',
            showRestaurantNameAndAddress: params['showRestaurantNameAndAddress'] === 'true',
            font: params['font'],
            restaurantId: params['restaurantId'],
            classes: decodeURIComponent(params['classes']),
            fontColor: decodeURIComponent(params['fontColor']),
            borderRadius: +decodeURIComponent(params['borderRadius']),
            backgroundColor: decodeURIComponent(params['backgroundColor']),
            containerColor: decodeURIComponent(params['backgroundColor']),
            id: '',

            maxBookingDays: 0,
            minNumberOfGuestsAcceptedOnline: 0,
            maxNumberOfGuestsAcceptedOnline: 0,
            servingAreaChoice: false,

        }

        console.log('Params:', this.isPreviewMode, this.previewConfiguration)

      });
    };
  };

  private getWidgetConfigurations() {
    if (this.tenantId) {

      if (this.isPreviewMode) {
        this.setStyle(this.previewConfiguration);
      } else {
        this.supabaseService.getWidgetConfigurations(this.tenantId!, this.restaurantId!).subscribe({
          next: response => {
            if(response.error) throw response.error;
            const data = response.data;
            if (!data) {
              return;
            }
            this.setStyle(data);
          },
          error: error => {
            console.error(error);
          }
        });
      }
    }
  };

  private setStyle(widgetConfigurations: WidgetConfigurations){
    if(widgetConfigurations.primaryColor){
      document.documentElement.style.setProperty('--primary-color', widgetConfigurations.primaryColor);
      // document.documentElement.style.setProperty('--p', widgetConfigurations.primaryColor);
      const primaryColorLight = hexToRgb(widgetConfigurations.primaryColor);
      // create a lighter color if primar color is set with opacity
      if(primaryColorLight){
        document.documentElement.style.setProperty('--primary-color-lighter', `rgba(${primaryColorLight.r}, ${primaryColorLight.g}, ${primaryColorLight.b}, 0.5)`);
      }
    }
    if(widgetConfigurations.primaryColorContrast){
      document.documentElement.style.setProperty('--primary-color-contrast', widgetConfigurations.primaryColorContrast);
    }
    if(widgetConfigurations.hasFrame){
      this.classes += ' shadow-xl';
    }

    if(widgetConfigurations.font){
      document.documentElement.style.fontFamily = widgetConfigurations.font;
      document.body.style.fontFamily = widgetConfigurations.font;
    }

    if(widgetConfigurations.classes){
      this.classes += ' ' + widgetConfigurations.classes;
    }

    if(widgetConfigurations.fontColor){
      document.body.style.color = widgetConfigurations.fontColor;
    }

    if(widgetConfigurations.borderRadius){
      document.documentElement.style.setProperty('--border-radius', widgetConfigurations.borderRadius + 'px');
    }

    if(widgetConfigurations.containerColor){
      document.documentElement.style.setProperty('--container-color', widgetConfigurations.containerColor);
    }

    if(widgetConfigurations.backgroundColor){
      document.body.style.backgroundColor = widgetConfigurations.backgroundColor;
    }

  };

};
