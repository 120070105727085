import {Injectable, OnInit} from '@angular/core';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import {I18nService} from "@app/i18n/i18n.service";
import {TranslationWidth} from "@angular/common";
import {DateTime} from "luxon";


const I18N_VALUES: any = {
  en: {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  },
  nb: {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  },
  // Add more languages as needed
};

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  private justATempDateTime = DateTime.now();
  constructor(private i18nService: I18nService) {
    super();
  }


  getWeekdayShortName(weekday: number): string {
    return 'not used';
  }

  getMonthShortName(month: number): string {
    return this.justATempDateTime.set({month: month}).toLocaleString({month: 'long'}, {locale: this.i18nService.currentLanguage});

  }

  getMonthFullName(month: number): string {
    return this.justATempDateTime.set({month: month}).toLocaleString({month: 'long'}, {locale: this.i18nService.currentLanguage});
  }

  getDayAriaLabel(date: { year: number, month: number, day: number }): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: "narrow" | "short" | "long" | undefined): string {
    return this.justATempDateTime.set({weekday: weekday}).toLocaleString({weekday: 'short'}, {locale: this.i18nService.currentLanguage});
  }
}
