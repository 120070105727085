<div class="flex items-center justify-center min-h-screen">
  <div class="container-background-color flex flex-col items-center gap-4 p-24 {{classes}}" *ngIf="!isLoading">
    <div class="flex flex-col items-center text-center mt-18">
      <h1 class="text-3xl md:text-3xl" translate>cancellationconfirmation.h1</h1>
      <p translate>cancellationconfirmation.desc</p>

      <button class="btn btn-primary mt-12"
              [routerLink]="['/booking']"
              [queryParams]="{ tenantId: tenantId, restaurantId: restaurantId }" translate>booking_review.new_booking_btn_label</button>
    </div>
  </div>
</div>
