import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { SeatlyBookingWidgetComponent } from './pages/seatly-booking-widget/seatly-booking-widget.component';
import { createCustomElement } from '@angular/elements';
import { SelectGuestsComponent } from './components/select-guests/select-guests.component';
import { TranslateModule } from '@ngx-translate/core';
import {Router, RouterModule} from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from './i18n';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SelectDateComponent } from './components/select-date/select-date.component';
import { SelectTimeComponent } from './components/select-time/select-time.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './components/stepper/stepper.component';
import { AppRoutingModule } from './app.routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ShimmerComponent } from './components/shimmer/shimmer.component';
import * as Sentry from "@sentry/angular";
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { BookingFailedComponent } from './components/booking-failed/booking-failed.component';
import {CustomDatepickerI18n} from "@app/i18n/CustomDatepickerI18n";
import {LocalizedDateStringPipe} from "@app/utils/localized-date-string.pipe";
import { BookingReviewComponent } from './pages/booking-review/booking-review.component';
import {CancellationConfirmedComponent} from "@app/pages/cancellation-confirmed/cancellation-confirmed.component";
import {WaitingListComponent} from "@app/pages/waiting-list/waiting-list.component";

@NgModule({
  declarations: [
    AppComponent,
    SeatlyBookingWidgetComponent,
    SelectGuestsComponent,
    SelectDateComponent,
    SelectTimeComponent,
    ContactInfoComponent,
    ConfirmationComponent,
    LoaderComponent,
    ShimmerComponent,
    StepperComponent,
    PhoneInputComponent,
    BookingFailedComponent,
    LocalizedDateStringPipe,
    BookingReviewComponent,
    CancellationConfirmedComponent,
    WaitingListComponent,
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule.forRoot([]),
    FormsModule,
    I18nModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
    }),
    BrowserAnimationsModule,
    NgbModule,
  ],
  bootstrap: [AppComponent], //remove on deploy, I guess?
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: true,
    }),
  }, {
    provide: Sentry.TraceService,
    deps: [Router],
  },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class AppModule {
  /** This creates the web components for the app */
  constructor(private injector: Injector) {
    // Convert `Component` to a custom element.
    const SeatlyBooking = createCustomElement(SeatlyBookingWidgetComponent, { injector: this.injector });
    // Register the custom element with the browser.
    customElements.define('seatly-booking-widget', SeatlyBooking);
  }

  ngDoBootstrap() {}
}
