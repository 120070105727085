<div class="container-background-color mt-12 flex flex-col items-center gap-4 pt-20 pb-10 {{classes}}" *ngIf="!isLoading">

  <div class="absolute top-[10px] right-[10px]">
    <app-language-selector />
  </div>

  <div class="flex flex-col items-center text-center">
    <h1 class="text-3xl md:text-3xl">{{restaurantName}}</h1>
    <p class="max-[320px]:text-xs" *ngIf="address && city && country">
      <a [href]="'https://www.google.com/maps/search/?api=1&query=' + restaurantName + ',' + city + ',' + country" target="_blank">
        {{address}}, {{city}}
      </a>
    </p>
  </div>

  <div class="w-full sm:w-[500px] flex flex-col gap-5 p-4" style="{{isPreviewMode ? 'margin-top: 20px!important' : ''}}">

      <div class="flex flex-col items-center gap-2 mt-12">
        <h1 *ngIf="isBookingFound" class="text-4xl font-semibold">{{'booking_review.title' | translate}} {{firstName}}</h1>
        <p class="text-center" *ngIf="!isWaitingList" translate>booking_review.your_reservation</p>
        <p class="text-center" *ngIf="isWaitingList" translate>booking_review.you_are_on_the_waiting_list</p>
        <p *ngIf="!isBookingFound" class="text-xl font-semibold" [translate]="'booking_review.error'"></p>
        <p *ngIf="!isBookingFound" class="text-sm" [translate]="'booking_review.questions'"></p>
      </div>

      <div *ngIf="isBookingFound" class="flex flex-col items-center gap-2 mt-2 mb-12">
        <p translate>{{ guests }} <span translate>booking_review.guests</span> </p>
        <p class=" text-3xl font-extrabold">{{ timeOfDay }}</p>
        <p class="text-center" *ngIf="isWaitingList && waitingListFlexibility && waitingListFlexibility === 30" translate>± 30 {{'common.minutes' | translate}}</p>
        <p class="text-center" *ngIf="isWaitingList && waitingListFlexibility && waitingListFlexibility === 60" translate>± 1 {{'common.hour' | translate}}</p>
        <p class="text-center" *ngIf="isWaitingList && waitingListFlexibility && waitingListFlexibility === 120" translate>± 2 {{'common.hours' | translate}}</p>
        <p>{{ getAsDatetime(date) | localizedDateString }}</p>
      </div>

      <div *ngIf="isBookingFound" class="flex flex-row justify-center gap-4 mt-12">
        <button class="btn btn-primary" (click)="newBookingHandler()" translate> booking_review.new_booking_btn_label </button>
        <button class="btn btn-primary" (click)="openConfirmationModal()" translate> booking_review.cancel_booking_btn_label </button>
      </div>

  </div>

  <div class="text-center text-[#adadad]">
    <p>{{'common.deliveredByResmium' | translate }} <a href="https://resmium.com">Resmium</a></p>
  </div>


  <!-- Popup Modal - Confirmation Box - Backdrop + Card -->
   <div class="absolute top-0 left-0 z-10 w-screen h-screen bg-black bg-opacity-70 flex items-center justify-center" *ngIf="isConfirmationModalVisible">
     <div class="lg:max-w-96 max-w-72 md:max-w-80 p-8 rounded-md bg-white flex flex-col gap-4">

      <h1 class="text-lg font-semibold" translate>booking_review.confimation_title</h1>

      <p class="font-light" translate>booking_review.confimation_description</p>

      <div class="font-light text-sm text-red-500" *ngIf="isErrorMessageDisplayed">
        <p translate>booking_review.confirmation_error</p>
        <p translate>booking_review.confirmation_contact</p>
      </div>

      <div class="flex gap-4 justify-end">
        <button class="btn btn-primary" (click)="closeConfirmationModal()" translate>booking_review.confirmation_close_btn_label</button>
        <button class="btn btn-primary" (click)="deleteBookingHandler()" translate>booking_review.confirmation_confirm_btn_label</button>
      </div>

     </div>
   </div>

</div>
