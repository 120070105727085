import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GeneralOpeningHours } from '@app/models/general-opening-hours.model';
import { NgbCalendar, NgbDate, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from "luxon";
import { DayOfWeek, getWeekStartByLocale } from 'weekstart';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.css'],
})
export class SelectDateComponent implements OnInit {
  @Input() maxBookingDays?: number;
  @Input() numberOfGuests: number = 0;
  @Input() selectedDate?: DateTime;
  // From API
  @Input() generalOpeningHours: GeneralOpeningHours[] = [];

  selectedDateForm!: FormGroup;
  firstDayOfWeek!: DayOfWeek;
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;
  startDate!: NgbDateStruct;

  @Output() onBack = new EventEmitter<boolean>();
  @Output() onSelectDate = new EventEmitter<DateTime>();

  isLoading = true;

  private openPeriods!: any[];
  private closedPeriods!: any[];
  private specialOpeningHours!: any[];
  restaurantHasOpeningHours: boolean = true;

  constructor(private calendar: NgbCalendar) {
  }

  ngOnInit(): void {
    this.restaurantHasOpeningHours = this.generalOpeningHours.length > 0;

    this.closedPeriods = this.generalOpeningHours.filter((f: any) => !f.isOpen);
    this.openPeriods = this.generalOpeningHours.filter((f: any) => f.isOpen);
    this.specialOpeningHours = this.generalOpeningHours.filter((f: any) => f.specialOpeningHoursThatShouldOverrideOthers);

    const today = this.calendar.getToday();
    this.minDate = today;
    if (this.maxBookingDays) {
      const maxBookingDate = this.calendar.getNext(today, 'd', this.maxBookingDays);
      this.maxDate = maxBookingDate;
    } else {
      this.maxDate = { ...today, year: today.year + 1 };
    }

    this.selectedDateForm = new FormGroup({
      date: new FormControl<NgbDateStruct | undefined>(undefined, [Validators.required]),
    });
    this.firstDayOfWeek = getWeekStartByLocale(Intl.Locale.name);

    if (this.selectedDate) {
      const formattedDate = new NgbDate(this.selectedDate.year, this.selectedDate.month, this.selectedDate.day);
      this.selectedDateForm.get('date')?.setValue(formattedDate);
      this.startDate = formattedDate;
    }

    this.isLoading = false;
  }

  getDisabledDates = (ngbDate: NgbDateStruct) => {
    const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    return !this.isRestaurantOpen(date);
  }

  isRestaurantOpen(date: Date): boolean {
    const thaDate = DateTime.fromJSDate(date).startOf('day');
    const currentWeekday = thaDate.weekday;

    let isOpen: boolean | null = null;

    // Check for special opening hours first
    for (const specialPeriod of this.specialOpeningHours) {
      let specialFrom = DateTime.fromJSDate(specialPeriod.activeFromDate).startOf('day');
      let specialTo = DateTime.fromJSDate(specialPeriod.activeToDate).startOf('day');

      if (specialPeriod.weekday === currentWeekday && thaDate >= specialFrom && thaDate <= specialTo) {
        return specialPeriod.isOpen;
      }
    }

    const thereWasNoSpecialOpeningHoursForTheCurrentDate = isOpen === null;
    if (thereWasNoSpecialOpeningHoursForTheCurrentDate) {

      for (const openPeriod of this.openPeriods) {
        let openFrom = DateTime.fromJSDate(openPeriod.activeFromDate).startOf('day');
        let openTo = DateTime.fromJSDate(openPeriod.activeToDate).startOf('day');

        if (openPeriod.weekday === currentWeekday && thaDate >= openFrom && thaDate <= openTo) {
          // da er vi i utgangspunktet åpen
          isOpen = true;
        }
      }

      for (const closedPeriod of this.closedPeriods) {
        let closedFrom = DateTime.fromJSDate(closedPeriod.activeFromDate).startOf('day');
        let closedTo = DateTime.fromJSDate(closedPeriod.activeToDate).startOf('day');

        const atleastSomePartOfTheDayIsOpen = this.openPeriods.find((f: any) => f.weekday === currentWeekday && (f.opens < closedPeriod.opens || f.closes > closedPeriod.closes) && thaDate >= closedFrom && thaDate <= closedTo);

        if (closedPeriod.weekday === currentWeekday && thaDate >= closedFrom && thaDate <= closedTo && !atleastSomePartOfTheDayIsOpen) {
          // da er vi stengt. Stengt overstyrer åpen
          isOpen = false;
        }
      }
    }

    return isOpen === null ? false : isOpen;
  }

  back() {
    this.onBack.emit(true);
  }

  submit() {
    this.selectedDateForm.markAllAsTouched();

    const date = this.selectedDateForm.get('date')?.value;
    if (date) {
      this.onSelectDate.emit(DateTime.local(date?.year, date?.month, date?.day));
    }
  }

}
