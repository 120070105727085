import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { convertMinutesSinceMidnightToHumanReadableTime } from '@app/utils/time';
import { DateTime } from 'luxon';
import {I18nService} from "@app/i18n";

export interface ContactInfoForm{
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  comment: string;
}

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.css'],
})
export class ContactInfoComponent implements OnInit, OnChanges{
  @Input() numberOfGuests: number = 0;
  @Input() selectedDate?: DateTime;
  @Input() selectedTime?: number = 0;
  @Input() isLoading: boolean = false;
  @Input() country?: string;

  selectedTimeHumanReadable?: string;

  @Output() onBack = new EventEmitter<boolean>();
  @Output() onSubmitContactInfo = new EventEmitter<ContactInfoForm>();

  contactInfoForm = new FormGroup({
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    comment: new FormControl(''),
  });

  constructor(
    private i18nService: I18nService,
  ) {}

  ngOnInit(): void {
    if(this.selectedTime){
      this.selectedTimeHumanReadable = convertMinutesSinceMidnightToHumanReadableTime(this.selectedTime, this.i18nService.use12HourClock);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if(propName === 'isLoading'){
        const change = changes[propName];
        if(change.currentValue === true){
          this.contactInfoForm.disable();
        }else{
          this.contactInfoForm.enable();
        }
      }
    }
  }

  addPhoneNumberToForm(phoneNumber: string) {
    this.contactInfoForm.patchValue({ phone: phoneNumber });
  }

  back(){
    this.onBack.emit(true);
  }

  submit(){
    this.contactInfoForm.markAllAsTouched();
    if(this.contactInfoForm.valid){
      const contactInfo = this.contactInfoForm.value as ContactInfoForm;
      this.onSubmitContactInfo.emit(contactInfo);
    }
  }
}
