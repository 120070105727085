import { Component, OnInit, OnDestroy, ViewEncapsulation, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { I18nService } from '@app/i18n';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SupabaseService } from '@app/services/supabase.service';
import { DateTime } from 'luxon';
import { hexToRgb } from '@app/utils/hex-to-rgb';
import { WidgetConfigurations } from '@app/models/widget-configurations.model';
import * as Sentry from "@sentry/angular";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { Location } from '@angular/common';
import {convertMinutesSinceMidnightToHumanReadableTime} from "@app/utils/time";
import {BookingRequest} from "@app/models/booking-request.model";


@UntilDestroy()
@Component({
  selector: 'booking-review-page',
  templateUrl: './waiting-list.component.html',
  styleUrls: ['./waiting-list.component.css', '../../../styles.css'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class WaitingListComponent implements OnInit, OnDestroy {

  tenantId?: string;
  restaurantId?: number;
  selectedDate: DateTime | undefined;

  // Restaurant Details
  restaurantName?: string;
  address?: string;
  city?: string;
  country?: string;
  contactEmail?: string;
  contactPhone?: string;

  availableTimes: { minutesFromMidnight: number, timeString: string }[] = [];

  minGuests: number = 1;
  maxGuests: number = 10;

  initialLoaderToFixBuxInLanguageSelector: boolean = true;
  isLoading: boolean = true;
  isRestaurantFound: boolean = false;


  form: FormGroup;

  // Widget Configuration
  classes: string = '';
  isPreviewMode: boolean = false;
  private previewConfiguration: WidgetConfigurations = {
    primaryColor: '#efc805',
    primaryColorContrast: '#d90c0c',
    hasFrame: true,
    showRestaurantNameAndAddress: true,
    font: 'Arial',
    fontColor: '#02e802',
    backgroundColor: '#e8e8e8',
    containerColor: '#fff',
    restaurantId: '0',
    id: '0',
    classes: '',
    borderRadius: 25,

    maxBookingDays: 365,
    maxNumberOfGuestsAcceptedOnline: 7,
    minNumberOfGuestsAcceptedOnline: 1,
    servingAreaChoice: true,
  };
  showFormNotValidMessage: boolean = false;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private location: Location,
    private supabaseService: SupabaseService,
    private route: ActivatedRoute,
    private fb: FormBuilder) {
    this.form = this.fb.group({
      desiredReservationTime: ['', Validators.required],
      flexibility: [null, Validators.required],
      numberOfGuests: [0, [Validators.required, Validators.min(this.minGuests), Validators.max(this.maxGuests)]],
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      comment: new FormControl(''),
    });
  };

  ngOnInit() {
    this.i18nSetup().then( async () => {
      this.getQueryParams();
    }).then(() => {
      this.getPreviewModeParams();
      this.getWidgetConfigurations();
      Sentry.setUser({
        'Tenant Id': this.tenantId,
        'Restaurant Id': this.restaurantId,
        'Preview mode': this.isPreviewMode,
      });

      this.initialLoaderToFixBuxInLanguageSelector = false;
    })
  };

  private getQueryParams() {

    this.route.queryParams.subscribe(async params => {

      this.tenantId = params.tenantId || undefined;
      this.restaurantId = params.restaurantId || undefined;
      let selectedDateTmp = params.selectedDate || undefined;
      if (selectedDateTmp) {
        this.selectedDate = DateTime.fromISO(selectedDateTmp) || undefined;
      }

      if (!this.tenantId || !this.restaurantId || !this.selectedDate) {
        console.error(
          'Missing one or more attributes: TenantID, RestaurantId, SelectedDate.',
        );
        this.router.navigate(['/attribute-error']);
      }

      await this.getRestaurantData();
    });

    this.route.queryParams.subscribe(params => {
      this.isPreviewMode = params['isPreviewMode'];
    });

  }

  private async i18nSetup() {
    // Setup translations
    await this.i18nService.init(environment.defaultLanguage);
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd).pipe(
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      switchMap(route => route.data),
      untilDestroyed(this),
    );
  };

  private async getRestaurantData() {

    if (!this.tenantId || !this.restaurantId) {
      console.error('Missing one or more attributes: TenantID, RestaurantId.');
      return;
    }

    try {
      const response = await firstValueFrom(this.supabaseService.getRestaurantInfo(this.tenantId, this.restaurantId + '', this.i18nService.currentLanguage));

      if (response.status === 200 && response.data.length > 0) {
        const data = response.data[0];

        this.restaurantName = data.name;
        this.address = data.address;
        this.city = data.city;
        this.country = data.country;
        this.contactEmail = data.contactEmail;
        this.contactPhone = data.contactPhone;

        this.titleService.setTitle(this.translateService.instant('common.title', { restaurantName: this.restaurantName }));

        if (this.selectedDate) {
          const openingHours = data.general_opening_hours.filter((oh) => oh.isOpen && oh.weekday === this.selectedDate?.weekday);
          const minOpens = openingHours.sort(
            (a, b) => a.opens - b.opens
          )[0];
          const maxCloses = openingHours.sort(
            (a, b) => b.closes - a.closes
          )[0];

          if (minOpens && maxCloses) {
            this.availableTimes = [];
            for (let i = minOpens.opens; i <= (maxCloses.closes - 120); i = i + data.reservationIntervals) {
              const timeString = convertMinutesSinceMidnightToHumanReadableTime(i, this.i18nService.use12HourClock);

              this.availableTimes.push({
                minutesFromMidnight: i,
                timeString: timeString
              });
            }
          }
        }

        this.minGuests = 1;
        this.maxGuests = data.maxNumberOfGuestsAcceptedOnline ?? 11;

        this.isRestaurantFound = true;
      } else {
        throw new Error(`No restaurant data found or received unexpected status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error retrieving restaurant data:', error);
      this.isRestaurantFound = false;
    } finally {
      this.isLoading = false;
      const event = new CustomEvent('hideFrameworkLoader');
      window.dispatchEvent(event);
    }
  };





  ngOnDestroy() {
    this.i18nService.destroy();
  };





  // Widget Configurations
  dateFormatOptions: Intl.DateTimeFormatOptions = {
    dateStyle: 'full',
  };
  private getPreviewModeParams() {

    if (this.isPreviewMode) {

      this.route.queryParams.subscribe(params => {

          this.previewConfiguration = {
            primaryColor: decodeURIComponent(params['primaryColor']),
            primaryColorContrast: decodeURIComponent(params['primaryColorContrast']),
            hasFrame: params['hasFrame'] === 'true',
            showRestaurantNameAndAddress: params['showRestaurantNameAndAddress'] === 'true',
            font: params['font'],
            restaurantId: params['restaurantId'],
            classes: decodeURIComponent(params['classes']),
            fontColor: decodeURIComponent(params['fontColor']),
            borderRadius: +decodeURIComponent(params['borderRadius']),
            backgroundColor: decodeURIComponent(params['backgroundColor']),
            containerColor: decodeURIComponent(params['backgroundColor']),
            id: '',

            maxBookingDays: 0,
            minNumberOfGuestsAcceptedOnline: 0,
            maxNumberOfGuestsAcceptedOnline: 0,
            servingAreaChoice: false,

        }

        console.log('Params:', this.isPreviewMode, this.previewConfiguration)

      });
    };
  };

  private getWidgetConfigurations() {
    if (this.tenantId) {

      if (this.isPreviewMode) {
        this.setStyle(this.previewConfiguration);
      } else {
        this.supabaseService.getWidgetConfigurations(this.tenantId, this.restaurantId + '').subscribe({
          next: response => {
            if(response.error) throw response.error;
            const data = response.data;
            if (!data) {
              return;
            }
            this.setStyle(data);
          },
          error: error => {
            console.error(error);
          }
        });
      }
    }
  };

  private setStyle(widgetConfigurations: WidgetConfigurations){
    if(widgetConfigurations.primaryColor){
      document.documentElement.style.setProperty('--primary-color', widgetConfigurations.primaryColor);
      // document.documentElement.style.setProperty('--p', widgetConfigurations.primaryColor);
      const primaryColorLight = hexToRgb(widgetConfigurations.primaryColor);
      // create a lighter color if primar color is set with opacity
      if(primaryColorLight){
        document.documentElement.style.setProperty('--primary-color-lighter', `rgba(${primaryColorLight.r}, ${primaryColorLight.g}, ${primaryColorLight.b}, 0.5)`);
      }
    }
    if(widgetConfigurations.primaryColorContrast){
      document.documentElement.style.setProperty('--primary-color-contrast', widgetConfigurations.primaryColorContrast);
    }
    if(widgetConfigurations.hasFrame){
      this.classes += ' shadow-xl';
    }

    if(widgetConfigurations.font){
      document.documentElement.style.fontFamily = widgetConfigurations.font;
      document.body.style.fontFamily = widgetConfigurations.font;
    }

    if(widgetConfigurations.classes){
      this.classes += ' ' + widgetConfigurations.classes;
    }

    if(widgetConfigurations.fontColor){
      document.body.style.color = widgetConfigurations.fontColor;
    }

    if(widgetConfigurations.borderRadius){
      document.documentElement.style.setProperty('--border-radius', widgetConfigurations.borderRadius + 'px');
    }

    if(widgetConfigurations.containerColor){
      document.documentElement.style.setProperty('--container-color', widgetConfigurations.containerColor);
    }

    if(widgetConfigurations.backgroundColor){
      document.body.style.backgroundColor = widgetConfigurations.backgroundColor;
    }

  };

  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid && this.tenantId && this.restaurantId && this.selectedDate) {
      this.isLoading = true;
      const isoDate = this.selectedDate.toISODate() ?? '';
      const bookingData: BookingRequest = {
        waitingList: true,
        waitingListFlexibility: this.form.get('flexibility')?.value,
        tenantId: this.tenantId,
        restaurantId: this.restaurantId,
        areaId: null,
        paxCount: this.form.get('numberOfGuests')?.value,
        date: isoDate,
        timeslot: this.form.get('desiredReservationTime')?.value,
        source: '',
        customerNotes: this.form.get('comment')?.value,
        customer: {
          firstname: this.form.get('firstname')?.value,
          lastname: this.form.get('lastname')?.value,
          phoneNumber: this.form.get('phone')?.value,
          email: this.form.get('email')?.value,
          gdprConsent: DateTime.now().plus({year: 2}).toFormat('yyyy-MM-dd'),
          desiredCommunicationLanguage: navigator.language.substring(0, 2),
        }

      }

      this.supabaseService.createBooking(bookingData).subscribe(
        response => {
          if (response.bookingStatus === 'Booking requested') {
            this.router.navigate(['m', response.modifyId]);
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        },
        (e) => {
          this.isLoading = false;
          console.error('Error', e);
        },
      );
    } else {
      this.showFormNotValidMessage = true;
    }
  }

  addPhoneNumberToForm(phoneNumber: string) {
    this.form.patchValue({ phone: phoneNumber });
  }

  back() {
    this.location.back();
  }
};
