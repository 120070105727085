import { Component, Input, OnInit } from '@angular/core';
import { BookingRequest } from '@app/models/booking-request.model';
import { convertMinutesSinceMidnightToHumanReadableTime } from '@app/utils/time';
import {DateTime} from "luxon";
import {I18nService} from "@app/i18n";


@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css'],
})
export class ConfirmationComponent implements OnInit{
  @Input() bookingRequest?: BookingRequest;
  @Input() restaurantName?: string;
  @Input() modifyId: string | undefined;
  @Input() contactEmail?: string;
  @Input() contactPhone?: string;

  selectedTimeHumanReadable?: string;


  constructor(
    private i18nService: I18nService,
  ) {}

  ngOnInit(): void {
    if(this.bookingRequest?.timeslot){
      this.selectedTimeHumanReadable = convertMinutesSinceMidnightToHumanReadableTime(this.bookingRequest.timeslot, this.i18nService.use12HourClock);
    }

    console.log(this.bookingRequest)
  }

  getAsDatetime(date: any): DateTime | undefined {
    if (date){
      return DateTime.fromJSDate(new Date(date));
    }

    return undefined;
  }
}
